import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buy: {
    id: 'plan.buy',
    defaultMessage: 'Buy',
  },
  signup: {
    id: 'plan.signup',
    defaultMessage: 'Sign up',
  },
  current: {
    id: 'plan.current',
    defaultMessage: 'Current',
  },
  price_month: {
    id: 'feature.month.price',
    defaultMessage: 'Monthly Price',
  },
  price_year: {
    id: 'feature.year.price',
    defaultMessage: 'Monthly Price',
  },
  custom: {
    id: 'plans.custom',
    defaultMessage: 'Custom',
  },
  customBtn: {
    id: 'plans.custom.button',
    defaultMessage: 'Contact Us',
  },
  basic: {
    id: 'plans.basic',
    defaultMessage: 'Basic',
  },
  community: {
    id: 'plans.community',
    defaultMessage: 'Community',
  },
  communityBtn: {
    id: 'plans.community.button',
    defaultMessage: 'Free',
  },
  smallBusinessBtn: {
    id: 'plans.smallBusiness.button',
    defaultMessage: 'Request Quote',
  },
  enterprise: {
    id: 'plans.enterprise',
    defaultMessage: 'Enterprise',
  },
  enterpriseBtn: {
    id: 'plans.enterprise.button',
    defaultMessage: '15 Day Trial',
  },
  enterprisePremium: {
    id: 'plans.enterprisePremium',
    defaultMessage: 'Enterprise Premium',
  },
  enterprisePremiumBtn: {
    id: 'plans.enterprisePremium.button',
    defaultMessage: 'Request Quote',
  },
  currentBtn: {
    id: 'plans.current.button',
    defaultMessage: 'Current Plan',
  },
  sandboxing: {
    id: 'feature.sandboxing',
    defaultMessage: 'Sandboxing',
  },
  sandboxRequest: {
    id: 'feature.sandboxRequest',
    defaultMessage: 'Sandbox Request',
  },
  sandboxRequestDescription: {
    id: 'feature.sandboxRequest.description',
    defaultMessage: 'Submit a File to the Sandbox',
  },
  sandboxSearch: {
    id: 'feature.sandboxSearch',
    defaultMessage: 'Sandbox Search',
  },
  sandboxSearchDescription: {
    id: 'feature.sandboxSearch.description',
    defaultMessage: 'Search Sandbox results',
  },
  apiRequestLimit: {
    id: 'feature.apiRequestLimit',
    defaultMessage: 'Daily API Request Limit',
  },
  apiRequestLimitDescription: {
    id: 'feature.apiRequestLimit.description',
    defaultMessage: 'The maximum total daily usage for an account across all features.',
  },
  additionalFeatures: {
    id: 'feature.additionalFeatures',
    defaultMessage: 'Additional Features',
  },
  hasSupport: {
    id: 'feature.hasSupport',
    defaultMessage: 'Enterprise-Class Support',
  },
  hasSupportDescription: {
    id: 'feature.hasSupport.description',
    defaultMessage:
      'We have your back. You can contact us via Discord, Slack, and Email to get assistance.',
  },
  createTeams: {
    id: 'feature.createTeams',
    defaultMessage: 'Team Access',
  },
  createTeamsDescription: {
    id: 'feature.createTeams.description',
    defaultMessage:
      'You can create team accounts to share a plan with your colleagues. Large enterprises may opt to create separate teams for different parts of the organization. Team accounts can only purchase plans with Team Access.',
  },
  integrationOk: {
    id: 'feature.integrationOk',
    defaultMessage: 'Integrations Support',
  },
  integrationOkDescription: {
    id: 'feature.integrationOk.description',
    defaultMessage:
      'Individual Plans only support the Autopsy Plugin Integration. Enterprise Plans support all TIPs, Autopsy Plugin, and Automation tools.',
  },
  integrationsOk: {
    id: 'feature.integrationsOk',
    defaultMessage: 'Integrations Support',
  },
  integrationsOkDescription: {
    id: 'feature.integrationsOk.description',
    defaultMessage:
      'Individual Plans only support the Autopsy Plugin Integration. Enterprise Plans support all TIPs, Autopsy Plugin, and Automation tools.',
  },
  privateComm: {
    id: 'feature.privateComm',
    defaultMessage: 'Private Communities',
  },
  privateCommDescription: {
    id: 'feature.privateComm.description',
    defaultMessage:
      'Invite-only communities to support specific use cases (e.g. mutual NDA among participants, GDPR compliance, etc).',
  },
  iocUser: {
    id: 'feature.iocUser',
    defaultMessage: 'IOC Searches',
  },
  iocUserDescription: {
    id: 'feature.iocUser.description',
    defaultMessage: 'Search for an artifact in the PolySwarm network by IOC',
  },
  tagsView: {
    id: 'feature.tagsView',
    defaultMessage: 'Tags View',
  },
  tagsViewDescription: {
    id: 'feature.tagsView.description',
    defaultMessage: 'View tags and families associated with an artifact in the PolySwarm network',
  },
  maxArtifactSize: {
    id: 'feature.maxArtifactSize',
    defaultMessage: 'Max Artifact Size',
  },
  maxArtifactSizeDescription: {
    id: 'feature.maxArtifactSize.description',
    defaultMessage:
      'Maximum size of an artifact that you can Scan in the PolySwarm network”, the size varies per account, so check their settings and then display the number rounded to the nearest megabyte (MB)',
  },
  taxii: {
    id: 'feature.taxii',
    defaultMessage: 'Stix / Taxii Access',
  },
  taxiiDescription: {
    id: 'feature.taxii.description',
    defaultMessage: 'Access PolySwarm threat intelligence feeds via Stix/Taxii',
  },
  taxiiServerAccess: {
    id: 'feature.taxiiServerAccess',
    defaultMessage: 'Taxii Server Access',
  },
  taxiiServerAccessDescription: {
    id: 'feature.taxiiServerAccess.description',
    defaultMessage: 'Access PolySwarm threat intelligence feeds via Stix/Taxii',
  },
  overageSupport: {
    id: 'feature.overageSupport',
    defaultMessage: 'Overages',
  },
  overageSupportDescription: {
    id: 'feature.overageSupport.description',
    defaultMessage:
      'You can exceed your monthly quota allotment by the Overage amount set on each feature that has a counting quota.',
  },
  taxiiTrustgroupAccess: {
    id: 'feature.taxiiTrustgroupAccess',
    defaultMessage: 'Taxii Trustgroup Access',
  },
  taxiiTrustgroupAccessDescription: {
    id: 'feature.taxiiTrustgroupAccess.description',
    defaultMessage: 'Access the Trustgroup threat intelligence feed via Stix/Taxii',
  },
  taxiiRansomwareAccess: {
    id: 'feature.taxiiRansomwareAccess',
    defaultMessage: 'Taxii Ransomware Access',
  },
  taxiiRansomwareAccessDescription: {
    id: 'feature.taxiiRansomwareAccess.description',
    defaultMessage: 'Access the Ransomware threat intelligence feed via Stix/Taxii',
  },
  hasNotifications: {
    id: 'feature.hasNotifications',
    defaultMessage: 'Notifications',
  },
  hasNotificationsDescription: {
    id: 'feature.hasNotifications.description',
    defaultMessage: 'Notifications',
  },
  hasStream: {
    id: 'feature.hasStream',
    defaultMessage: 'Streams & Feeds',
  },
  hasStreamAccess: {
    id: 'feature.hasStreamAccess',
    defaultMessage: 'Stream Access',
  },
  hasStreamAccessDescription: {
    id: 'feature.hasStreamAccess.description',
    defaultMessage: 'Stream Access',
  },
  scans: {
    id: 'feature.scans',
    defaultMessage: 'Scans',
  },
  scansDescription: {
    id: 'feature.scans.description',
    defaultMessage: 'Scan a file or URL.',
  },
  downloads: {
    id: 'feature.downloads',
    defaultMessage: 'Downloads',
  },
  limited: {
    id: 'feature.limited',
    defaultMessage: 'Limited',
  },
  downloadsDescription: {
    id: 'feature.downloads.description',
    defaultMessage: 'Download artifacts from the PolySwarm network.',
  },
  hashSearches: {
    id: 'feature.hashSearches',
    defaultMessage: 'Hash Searches',
  },
  hashSearchesDescription: {
    id: 'feature.hashSearches.description',
    defaultMessage: 'Search for an artifact on the PolySwarm network by hash.',
  },
  metadataSearches: {
    id: 'feature.metadataSearches',
    defaultMessage: 'Metadata Searches',
  },
  metadataSearchesDescription: {
    id: 'feature.metadataSearches.description',
    defaultMessage: 'Search for an artifact on the PolySwarm network by metadata.',
  },
  hunting: {
    id: 'feature.hunting',
    defaultMessage: 'Hunting',
  },
  liveHunts: {
    id: 'feature.liveHunts',
    defaultMessage: 'Live Hunts',
  },
  liveHuntsDescription: {
    id: 'feature.liveHunts.description',
    defaultMessage:
      'Live Hunting will match your active YARA ruleset against new artifacts as they are submitted.',
  },
  historicalHunts: {
    id: 'feature.historicalHunts',
    defaultMessage: 'Historical Hunts',
  },
  historicalHuntsDescription: {
    id: 'feature.historicalHunts.description',
    defaultMessage:
      'Historical Hunting will match your YARA ruleset against previously submitted artifacts.',
  },
  huntingYaraRules: {
    id: 'feature.huntingYaraRules',
    defaultMessage: 'Hunting YARA Rules',
  },
  huntingYaraRulesDescription: {
    id: 'feature.huntingYaraRules.description',
    defaultMessage: 'YARA Rules used for Live and Historical Hunting in the PolySwarm network.',
  },
  month: {
    id: 'feature.month',
    defaultMessage: '/mo',
  },
  day: {
    id: 'feature.day',
    defaultMessage: '/day',
  },
  minute: {
    id: 'feature.minute',
    defaultMessage: '/min',
  },
  active: {
    id: 'feature.active',
    defaultMessage: 'active',
  },
  unlimited: {
    id: 'feature.unlimited',
    defaultMessage: 'Unlimited',
  },
});
