import React, { useState, useEffect } from 'react';
import { omit } from 'lodash';
import { IContactFormValues } from './ContactForm';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/root';
import api from 'services/api';
import { showNotification } from 'state/notification/actions';
import { defineMessages } from 'react-intl';
import { messages as errorMessages } from 'views/components/error/ErrorPageContent/messages';
import ContactSteps from '.';
import { CheckboxOption } from 'views/pages/AuthCallbackPage/AccountSignupForm/CheckboxArrayField';

export const PRICING_SIDEBAR_STORAGE_KEY = 'PRICING_SIDEBAR_STORAGE';

interface IContactContainerProps {
  onClose: () => void;
  options?: CheckboxOption[];
  withOptions?: boolean;
  withMessage?: boolean;
  defaultValues?: IContactFormValues;
}

const messages = defineMessages({
  unauthorized: {
    id: 'pricingpage.form.error.unauthorized',
    defaultMessage: 'Please login or create an account',
  },
  forbidden: {
    id: 'pricingpage.form.error.forbidden',
    defaultMessage: 'You are not allowed to submit form on behalf of another user',
  },
});

const ContactContainer = ({
  onClose,
  options,
  withOptions,
  withMessage,
  defaultValues,
}: IContactContainerProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<IContactFormValues>({});

  const dispatch = useDispatch();

  const user = useSelector((store: RootState) => store.user);

  const isAuthenticated = !!user.account;

  const savedInitialValuesRaw = localStorage.getItem(PRICING_SIDEBAR_STORAGE_KEY);
  let savedInitialValues = {};
  if (savedInitialValuesRaw) {
    savedInitialValues = JSON.parse(savedInitialValuesRaw);
  }

  useEffect(() => {
    let values = Object.assign(defaultValues || {}, savedInitialValues);
    if (isAuthenticated && user.profile) {
      values = Object.assign(values, {
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        email: user.profile.email,
        jobTitle: user.profile.title || '',
        company: user.profile.organization || '',
      });
    }

    setInitialValues(values);
  }, [isAuthenticated]); // eslint-disable-line

  const _onSubmitForm = async (values: IContactFormValues) => {
    // Save values for case if we'll not be able to save data
    setInitialValues(Object.assign(initialValues, values));

    // If user is not authenticated, persist form data
    if (!isAuthenticated) {
      localStorage.setItem(PRICING_SIDEBAR_STORAGE_KEY, JSON.stringify(omit(values, 'options')));
      dispatch(
        showNotification({ status: 'failure', message: messages.unauthorized, delay: 5000 })
      );
      return;
    }

    setLoading(true);

    try {
      const { message } = await api.submitPricingForm(values).then((res) => res.data);
      if (message === 'created' || message === 'Pricing email sent') {
        setCurrentStep(2);
      }
    } catch (e) {
      const errorType = e.response.data.errorType;
      if (errorType === 'unauthorized') {
        dispatch(
          showNotification({ status: 'failure', message: messages.unauthorized, delay: 5000 })
        );
      } else if (errorType === 'forbidden') {
        dispatch(showNotification({ status: 'failure', message: messages.forbidden, delay: 5000 }));
      } else {
        dispatch(
          showNotification({
            status: 'failure',
            message: errorMessages.somethingWrong,
            delay: 5000,
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContactSteps
      onSubmit={_onSubmitForm}
      initialValues={initialValues}
      loading={loading}
      currentStep={currentStep}
      onClose={onClose}
      options={options}
      withOptions={withOptions}
      withMessage={withMessage}
    />
  );
};

export default ContactContainer;
