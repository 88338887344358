import React, { useEffect, useState, useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { first } from 'lodash';
import { useIntl, defineMessages } from 'react-intl';
import { AccountPlan, EAccountPlans } from 'models/Account';
import { getTeamAccount } from 'state/team/actions';
import { AccountActionName } from 'state/account/types';
import { TeamActionName } from 'state/team/types';
import { combinedUserActions, useUser } from 'views/components/providers/UserProvider';
import { getAccountPlans } from 'state/account/actions';
import { getUserAccount } from 'state/user/actions';
import { useAuth } from 'views/components/providers/AuthProvider';
import { RootState } from 'state/root';
import styles from 'views/styles';
import AppLoader from 'views/components/request/AppLoader';
import ComparePlansTable from 'views/components/account/ComparePlans/ComparePlansTable';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { selectPlan } from 'state/plans/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import ability from 'config/ability';

interface IPricingComparePlansProps {
  onChangeTab?: (tab: ETabName) => void;
}

interface StateProps {
  plans: AccountPlan[];
  selectedPlan: string | null;
  currentPlan?: string;
  processingPlan?: string | null;
  interval: string;
  isTeam: boolean;
}

export enum ETabName {
  INDIVIDUAL,
  ENTERPRISE,
}

const PLANS = {
  [ETabName.INDIVIDUAL]: ['Community', 'Basic'],
  [ETabName.ENTERPRISE]: ['Enterprise', 'Enterprise Premium', 'Custom'],
};

const messages = defineMessages({
  individualTabHeading: {
    id: 'pricingPanel.pricing.heading.individual',
    defaultMessage: 'Choose your plan and buy it online',
  },
  enterpriseTabHeading: {
    id: 'pricingPanel.pricing.heading.enterprise',
    defaultMessage: 'Choose your plan',
  },
});

export const PricingComparePlans = ({ onChangeTab }: IPricingComparePlansProps) => {
  const intl = useIntl();
  const params = useParams<{ plan?: string }>();
  const history = useHistory();
  const [tab, setTab] = useState(ETabName.INDIVIDUAL);
  const [hasSelected, setHasSelected] = useState(false);
  const [disabledPlans, setDisabledPlans] = useState<boolean | string[] | undefined>();

  const dispatch = useDispatch<Dispatch>();
  const { isAuthenticated } = useAuth();
  const user = useUser();

  const { classes } = useStyles();

  const { plans, selectedPlan, currentPlan, processingPlan, interval, isTeam } = useSelector<
    RootState,
    StateProps
  >(({ account: { plans, context }, plans: { selected: selectedPlan, interval }, user, team }) => {
    let currentPlan = (user.account && user.account.paymentPlan?.id) || undefined;
    if (context && context.team && team.account) {
      currentPlan = team.account.paymentPlan?.id || undefined;
    }

    const subscriptionTransactions = first(user.account?.subscriptionTransactions);

    return {
      plans: plans.filter((plan) => plan.name !== EAccountPlans.anonymous),
      processingPlan: subscriptionTransactions?.locked
        ? subscriptionTransactions?.stripePlanId
        : null,
      currentPlan,
      selectedPlan,
      interval,
      isTeam: !!context && !!context.team,
    };
  });

  const onSetTab = useCallback(
    (tab: ETabName) => {
      setTab(tab);

      if (tab === ETabName.INDIVIDUAL) {
        history.push('/pricing/individual');
      }
      if (tab === ETabName.ENTERPRISE) {
        history.push('/pricing/enterprise');
      }
    },
    [history]
  );

  const _onChangeTab = (_: any, tab: ETabName) => {
    onSetTab(tab);

    if (onChangeTab) {
      onChangeTab(tab);
    }

    if (!hasSelected && !isTeam) {
      _setPlan(tab);
    }
  };

  const _onChangePlan = (plan: AccountPlan) => {
    setHasSelected(true);
    dispatch(selectPlan(plan.name));
  };

  const _getTeamAccount = useCallback(
    (accountNumber: number) => dispatch(getTeamAccount(accountNumber)),
    [dispatch]
  );

  const _getPlans = useCallback(() => dispatch(getAccountPlans()), [dispatch]);

  const _getCurrentPlan = useCallback(() => {
    if (isAuthenticated) {
      dispatch(getUserAccount());
      if (user.context && user.context.team) {
        _getTeamAccount(user.context.accountNumber);
      }
    }
  }, [user.context, isAuthenticated, _getTeamAccount, dispatch]);

  const _setPlan = useCallback(
    (tab: ETabName) => {
      if (tab === ETabName.ENTERPRISE) {
        dispatch(selectPlan(EAccountPlans.enterprise));
      } else {
        dispatch(selectPlan(EAccountPlans.basic));
      }
    },
    [dispatch]
  );

  const _getPlanData = useCallback(() => {
    _getPlans();
    _getCurrentPlan();
  }, [_getPlans, _getCurrentPlan]);

  useEffect(() => {
    if (isTeam) {
      if (ability.cannot('manage', 'TeamBilling')) {
        setDisabledPlans(true);
      } else {
        setDisabledPlans([EAccountPlans.community]);
      }
    }
  }, [isTeam]);

  useEffect(() => {
    _getPlanData();
  }, [_getPlanData, _setPlan, tab]);

  useEffect(() => {
    if (isAuthenticated && user.context) {
      _getTeamAccount(user.context.accountNumber);
      /*
       * If pricing page initializes with a pricing URL (/pricing/:plan) where
       * plan is set then pricing panel updates to the specific tab.
       */
      if ((!!user.context?.team && tab !== ETabName.ENTERPRISE) || params.plan === 'enterprise') {
        onSetTab(ETabName.ENTERPRISE);
        _setPlan(ETabName.ENTERPRISE);
      } else {
        onSetTab(ETabName.INDIVIDUAL);
        _setPlan(ETabName.INDIVIDUAL);
      }
    }
  }, [_getTeamAccount, _setPlan, isAuthenticated, params.plan, onSetTab, user.context, tab]);

  const paymentPlans = plans.filter((plan) => {
    if (plan.interval !== interval) {
      return false;
    }
    return PLANS[tab].indexOf(plan.name as any) !== -1;
  });

  return (
    <AppLoader
      loadingActions={[
        ...combinedUserActions,
        AccountActionName.GET_ACCOUNT_PLANS,
        TeamActionName.GET_TEAM_ACCOUNT,
      ]}
      onReload={_getPlanData}
    >
      <div css={classes.root}>
        <h1 css={classes.heading}>
          {tab === ETabName.INDIVIDUAL
            ? intl.formatMessage(messages.individualTabHeading)
            : intl.formatMessage(messages.enterpriseTabHeading)}
        </h1>
        <Tabs
          css={classes.tabs}
          indicatorColor='primary'
          value={tab}
          onChange={_onChangeTab}
          centered
        >
          <Tab
            disabled={!!user?.context?.team}
            css={classes.tab}
            value={ETabName.INDIVIDUAL}
            label='Individual Plans'
          />
          <Tab css={classes.tab} value={ETabName.ENTERPRISE} label='Enterprise Plans' />
        </Tabs>

        <ComparePlansTable
          className='h-mb-sm'
          plans={paymentPlans}
          selectedPlan={selectedPlan!}
          currentPlan={currentPlan}
          processingPlan={processingPlan}
          disabledPlans={disabledPlans}
          onClick={_onChangePlan}
          interval={interval}
        />
      </div>
    </AppLoader>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: `${styles.spacing.md} ${styles.spacing.grid} ${styles.spacing.xxs}`,
    },
    heading: {
      textAlign: 'center',
      fontSize: styles.font.size.h3,
      fontWeight: styles.font.weight.medium,
      marginBottom: styles.spacing.tiny,
    },
    subheader: {
      textAlign: 'center',
      fontSize: styles.font.size.p,
      marginBottom: styles.spacing.tiny,
    },
    planPeriodSwitch: {
      textAlign: 'center',
    },
    tabs: {
      marginBottom: styles.spacing.xxs,
    },
    tab: {
      fontWeight: styles.font.weight.normal,
      fontSize: '1.8rem !important',
      paddingLeft: '50px !important',
      paddingRight: '50px !important',
      margin: '0px !important',
    },
  },
  light: {
    heading: {
      color: styles.color.black,
    },
    subheader: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    heading: {
      color: styles.color.white,
    },
    subheader: {
      color: styles.color.xLightGrey,
    },
  },
});
