import React, { useEffect, useCallback } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'views/components/Icon';
import styles from 'views/styles';
import { AccountFeatureTag } from 'models/Account';
import { RootState } from 'state/root';
import { Account, AccountUsage } from 'models/Account';
import { transformFeatureUsage } from 'state/utils/usage';
import UsageTable from 'views/components/table/UsageTable';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import {
  numberFeatures,
  booleanFeatures,
} from 'views/pages/TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamUsageTab';
import { AccountFeature } from 'models/Account';
import formatBytes from 'utils/formatBytes/formatBytes';
import tenant from 'tenants';

interface StateProps {
  account?: Account;
  features: AccountFeature[];
  featuresUsage: AccountUsage[];
}

export const AccountUsageTab = () => {
  const intl = useIntl();
  const { theme } = useStyles();

  const { featuresUsage } = useSelector<RootState, Partial<StateProps>>(({ user }) => {
    return {
      featuresUsage: user?.account?.features ? transformFeatureUsage(user.account.features) : [],
      features: user?.account?.features,
    };
  });

  const usageByFeature = [...numberFeatures, ...booleanFeatures]
    .filter(
      (feature) =>
        !tenant.disabledUsageFeatures?.includes(feature as (typeof tenant.disabledUsageFeatures)[0])
    )
    .map((feature) => {
      const featureUsage = featuresUsage?.find((u) => u.feature === feature);
      if (featureUsage) {
        return { ...featureUsage, isIncludedOnPlan: true };
      }
      return {
        feature: feature as AccountFeatureTag,
        baseUses: 0,
        baseQuota: 0,
        numUses: 0,
        remainingUses: 0,
        totalAvail: 0,
        overage: 0,
        value: false,
        isIncludedOnPlan: false,
      } as any;
    });

  if (usageByFeature?.length <= 0) return <PanelLoader />;

  return (
    <Container>
      <Header>Quotas</Header>
      {usageByFeature.length ? (
        <UsageTable
          usage={usageByFeature.filter((usage) => numberFeatures.includes(usage.feature))}
          overageSupportFeature={false}
        />
      ) : null}
      <Header>Features</Header>
      <Features>
        {usageByFeature
          .filter((usage) => booleanFeatures.includes(usage.feature))
          .map((item) => (
            <FeatureIcon isDark={theme === 'dark'} active={item.isIncludedOnPlan}>
              {intl.formatMessage({ id: `feature.${item.feature}` })}
              {item.feature === 'maxArtifactSize' && item.baseUses
                ? ` (${formatBytes(item.baseUses)})`
                : ''}
              <Tooltip
                placement='top'
                title={intl.formatMessage({ id: `feature.${item.feature}.description` })}
              >
                <div>
                  <Icon className='helpIcon' name='info' />
                </div>
              </Tooltip>
            </FeatureIcon>
          ))}
      </Features>
    </Container>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {},
  dark: {},
});

const FeatureIcon = styled.div<{ active: boolean; isDark: boolean }>`
  display: flex;
  color: ${(props) =>
    props.active
      ? props.isDark
        ? styles.color.white
        : styles.color.black
      : styles.color.xLightGrey};

  & .helpIcon {
    cursor: help;
    font-size: 0.8rem;
    padding-left: 1rem;
    position: relative;
    color: styles.color.xLightGrey;
  }
`;

const Header = styled.h1`
  font-size: 2.8rem;
  font-weight: ${styles.font.weight.bold};
  margin-left: 3rem;
`;

const Container = styled.div`
  padding: 1.7rem 0;
  padding-left: 0rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.7rem;
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  width: 100%;
  margin-left: 3rem;
`;
