import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';

import ErrorModal from './ErrorModal';
import NotificationModal from './NotificationModal';
import ScannerDetailsModal from 'views/pages/ScanPage/ScanResultsPage/modals/ScannerDetailsModal/ScannerDetailsModalContainer';
import ComparePlansModal from 'views/components/account/ComparePlans/modals/ComparePlansModal';
import ComparePlansSuccessModal from 'views/components/account/ComparePlans/modals/ComparePlansSuccessModal';
import MultifactorAuthModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/AdvancedTab/modals/MultifactorAuthModal';
import ConfirmRemoveMultifactorAuthModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/AdvancedTab/modals/ConfirmRemoveMultifactorAuthModal';
import ArchiveUserModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/AdvancedTab/modals/ArchiveUserModal';
import CreateTeamModal from 'views/pages/TeamDetailPage/modals/CreateTeamModal';
import UpdateTeamModal from 'views/pages/TeamDetailPage/modals/UpdateTeamModal';
import LeaveTeamModal from 'views/pages/TeamDetailPage/modals/LeaveTeamModal';
import ArchiveTeamModal from 'views/pages/TeamDetailPage/modals/ArchiveTeamModal';
import ArchiveTeamCompleteModal from 'views/pages/TeamDetailPage/modals/ArchiveTeamCompleteModal';
import InviteTeamMemberModal from 'views/pages/TeamDetailPage/modals/InviteTeamMemberModal';
import UpdateTeamMemberModal from 'views/pages/TeamDetailPage/modals/UpdateTeamMemberModal';
import ArchiveTeamMemberModal from 'views/pages/TeamDetailPage/modals/ArchiveTeamMemberModal';
import TeamMemberInvitationModal from 'views/pages/AuthCallbackPage/modals/TeamMemberInvitationModal';
import CreateMicroengineModal from 'views/pages/MicroenginesPage/modals/CreateMicroengineModal';
import UpdateMicroengineModal from 'views/pages/MicroenginesPage/modals/UpdateMicroengineModal';
import ArchiveMicroengineModal from 'views/pages/MicroenginesPage/modals/ArchiveMicroengineModal';
import SetGroundTruthModal from 'views/pages/MicroenginesPage/modals/SetGroundTruthModal';
import RulesetModal from 'views/pages/HuntPage/modals/RulesetModal';
import ViewRulesetModal from 'views/pages/HuntPage/modals/ViewRulesetModal';
import RunLiveHuntModal from 'views/pages/HuntPage/modals/RunLiveHuntModal';
import CancelLiveHuntModal from 'views/pages/HuntPage/modals/CancelLiveHuntModal';
import CancelHistoricalHuntModal from 'views/pages/HuntPage/modals/CancelHistoricalHuntModal';
import DeleteHuntModal from 'views/pages/HuntPage/modals/DeleteHuntModal';
import RunHistoricalHuntModal from 'views/pages/HuntPage/modals/RunHistoricalHuntModal';
import DeleteRulesetModal from 'views/pages/HuntPage/modals/DeleteRulesetModal';
import InfoModal from 'views/pages/HuntPage/modals/InfoModal';
import HistoricalInfoModal from 'views/pages/HuntPage/modals/HistoricalInfoModal';
import FilterModal from 'views/pages/HuntPage/modals/FilterModal';
import EmailVerifiactionSuccessModal from 'views/pages/EmailVerifiactionPage/modals/EmailVerificationSuccessModal';
import CancelPendingPlanModal from 'views/components/account/PaymentPlans/modals/CancelPendingPlanModal';
import ConfirmApiKeyCreation from 'views/components/table/ApiKeysTable/ConfirmApiKeyCreation';
import ConfirmApiKeyDeletion from 'views/components/table/ApiKeysTable/ConfirmApiKeyDeletion';
import ConfigureWithdrawalModal from 'views/components/table/WalletsTable/modals/ConfigureWithdrawal';
import ConfigureWithdrawalSuccessModal from 'views/components/table/WalletsTable/modals/ConfigureWithdrawalSuccess';
import ConfigureWithdrawalsAlertModal from 'views/components/table/WalletsTable/modals/ConfigureWithdrawalsAlert';
import FACheckModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/modals/FACheck';
import SessionExpiredModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/modals/SessionExpired';
import RedeemRewardsModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/modals/RedeemRewards';
import RedeemPreviewModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/modals/RedeemPreview';
import RedeemSuccessModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/RewardsTab/modals/RedeemSuccess';
import ConfirmIntegrationCreation from 'views/components/table/IntegrationsTable/ConfirmIntegrationCreation';
import ConfirmIntegrationDeletion from 'views/components/table/IntegrationsTable/ConfirmIntegrationDeletion';
import FileShareDataModal from 'views/pages/ScanPage/FileUpload/FileShareDataModal';
import SubmitToSandboxModal from 'views/pages/SandboxPage/modals/SubmitToSandboxModal';
import MetadataModal from 'views/pages/TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamTeamActivityTab/MetadataModal';
import FalsePositiveModal from 'views/pages/ScanPage/ScanResultsPage/modals/FalsePositiveModal';
import GenerateReportModal from 'views/pages/ScanPage/ScanResultsPage/modals/GenerateReportModal';
import AddLimitedAccessModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/AddLimitedAccessModal';
import FilterTeamAPIKeysModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/FilterTeamAPIKeysModal';
import EditApiKeyTagsModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/EditApiKeyTagsModal';
import FilterApiKeyTagsModal from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/FilterApiKeyTagsModal';

export const MODAL_REGISTRY = {
  ERROR_MODAL: ErrorModal,
  NOTIFICATION_MODAL: NotificationModal,
  SCANNER_DETAILS: ScannerDetailsModal,
  PLAN_SIGNUP: ComparePlansModal,
  PLAN_SIGNUP_SUCCESS: ComparePlansSuccessModal,
  MULTIFACTOR_AUTH: MultifactorAuthModal,
  REMOVE_MULTIFACTOR_AUTH: ConfirmRemoveMultifactorAuthModal,
  CONFIRM_APIKEY_CREATION: ConfirmApiKeyCreation,
  CONFIRM_APIKEY_DELETION: ConfirmApiKeyDeletion,
  ARCHIVE_USER: ArchiveUserModal,
  CREATE_TEAM: CreateTeamModal,
  UPDATE_TEAM: UpdateTeamModal,
  ARCHIVE_TEAM: ArchiveTeamModal,
  ARCHIVE_TEAM_COMPLETE: ArchiveTeamCompleteModal,
  INVITE_TEAM_MEMBER: InviteTeamMemberModal,
  UPDATE_TEAM_MEMBER: UpdateTeamMemberModal,
  ARCHIVE_TEAM_MEMBER: ArchiveTeamMemberModal,
  LEAVE_TEAM: LeaveTeamModal,
  TEAM_MEMBER_INVITATION: TeamMemberInvitationModal,
  CREATE_USER_MICROENGINE: CreateMicroengineModal,
  UPDATE_USER_MICROENGINE: UpdateMicroengineModal,
  ARCHIVE_USER_MICROENGINE: ArchiveMicroengineModal,
  SET_GROUND_TRUTH_MODAL: SetGroundTruthModal,
  ADD_EDIT_RULESET: RulesetModal,
  VIEW_RULESET: ViewRulesetModal,
  CANCEL_LIVE_HUNT: CancelLiveHuntModal,
  CANCEL_HISTORICAL_HUNT: CancelHistoricalHuntModal,
  DELETE_HUNT: DeleteHuntModal,
  RUN_LIVE_HUNT: RunLiveHuntModal,
  RUN_HISTORICAL_HUNT: RunHistoricalHuntModal,
  DELETE_RULESET: DeleteRulesetModal,
  INFO_MODAL: InfoModal,
  HISTORICAL_INFO_MODAL: HistoricalInfoModal,
  FILTER_MODAL: FilterModal,
  EMAIL_VERIFICATION_SUCCESS: EmailVerifiactionSuccessModal,
  CANCEL_PENDING_PLAN: CancelPendingPlanModal,
  CONFIGURE_WITHDRAWAL: ConfigureWithdrawalModal,
  CONFIGURE_WITHDRAWAL_SUCCESS: ConfigureWithdrawalSuccessModal,
  CONFIGURE_WITHDRAWALS_ALERT: ConfigureWithdrawalsAlertModal,
  FA_CHECK: FACheckModal,
  REDEEM_REWARDS: RedeemRewardsModal,
  REDEEM_PREVIEW: RedeemPreviewModal,
  REDEEM_SUCCESS: RedeemSuccessModal,
  SESSION_EXPIRED: SessionExpiredModal,
  CONFIRM_INTEGRATION_CREATION: ConfirmIntegrationCreation,
  CONFIRM_INTEGRATION_DELETION: ConfirmIntegrationDeletion,
  FILE_SHARE_DATA_MODAL: FileShareDataModal,
  SUBMIT_TO_SANDBOX_MODAL: SubmitToSandboxModal,
  METADATA_MODAL: MetadataModal,
  FALSE_POSITIVE_MODAL: FalsePositiveModal,
  GENERATE_REPORT: GenerateReportModal,
  ADD_LIMITED_ACCESS: AddLimitedAccessModal,
  FILTER_TEAM_API_KEYS: FilterTeamAPIKeysModal,
  FILTER_API_KEY_TAGS: FilterApiKeyTagsModal,
  EDIT_API_KEY_TAGS: EditApiKeyTagsModal,
};

export type ModalName = Extract<keyof typeof MODAL_REGISTRY, string>;

const ModalConductor = () => {
  const { currentModal } = useSelector((state: RootState) => state.modal);
  if (!currentModal) return null;
  return React.createElement(MODAL_REGISTRY[currentModal]);
};

export default ModalConductor;
