import React, { Fragment, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { find, filter, some, indexOf } from 'lodash';
import { FormattedNumber, useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { messages as featureMessages } from './messages';
import {
  AccountFeatureValue,
  AccountPlan,
  EAccountPlans,
  AccountFeatureSlug,
} from 'models/Account';
import { accountFeatures, getPlanSlugByName } from 'state/utils/account';
import { useSystem } from 'views/components/providers/SystemProvider';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import styles from 'views/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Scrollbar from 'views/components/layout/Scrollbar';
import { format as formatDate } from 'date-fns';

export interface IComparePlansTable {
  className?: string;
  plans: AccountPlan[];
  currentPlan?: string;
  processingPlan?: string | null;
  selectedPlan?: string;
  disabledPlans?: boolean | string[];
  hasPricing?: boolean;
  interval?: string;
  onClick?: (plan: AccountPlan) => void;
  onCancelPlanChange?: () => void;
}

interface IFeaturesGroup {
  name: AccountFeatureSlug;
  features: AccountFeatureSlug[];
}

const messages = defineMessages({
  current: {
    id: 'comparePlansTable.currentPlan',
    defaultMessage: 'Active Plan',
  },
  pending: {
    id: 'comparePlansTable.pendingPlan',
    defaultMessage: 'Next Plan',
  },
  startDate: {
    id: 'comparePlansTable.startDate',
    defaultMessage: 'Start Date:',
  },
  endDate: {
    id: 'comparePlansTable.endDate',
    defaultMessage: 'End Date:',
  },
});

const FEATURE_GROUPS: IFeaturesGroup[] = [
  {
    name: 'sandboxing',
    features: ['sandboxRequest', 'sandboxSearch'],
  },
  {
    name: 'hunting',
    features: ['liveHunts', 'historicalHunts', 'huntingYaraRules'],
  },
  {
    name: 'hasStream',
    features: ['hasStreamAccess', 'taxii'],
  },
  {
    name: 'additionalFeatures',
    features: ['hasSupport', 'privateComm', 'createTeams', 'integrationsOk'],
  },
];

const ComparePlansTable = (props: IComparePlansTable) => {
  const { className, plans, selectedPlan, hasPricing = false, interval } = props;
  const { classes } = useStyles();

  plans.forEach((plan) => {
    FEATURE_GROUPS.forEach((group) => {
      plan.features[group.name] = {
        value: some(group.features, (feature) => {
          return !!plan.features[feature].value;
        }),
      };
    });
  });

  return (
    <div className={className} css={classes.wrapper}>
      <Scrollbar>
        <Table
          css={classes.table}
          style={{ width: plans.length === 1 ? 'calc(20rem + 40%)' : '100%' }}
        >
          <TableHead>
            <CompareHeader plans={plans} selectedPlan={selectedPlan} />
          </TableHead>
          <TableBody>
            <CompareRows plans={plans} selectedPlan={selectedPlan} hasPricing={hasPricing} />
            {hasPricing && (
              <ComparePrices plans={plans} selectedPlan={selectedPlan} interval={interval} />
            )}
            <CompareSelect {...props} />
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  );
};

const CompareHeader = ({
  plans,
  selectedPlan,
}: {
  plans: AccountPlan[];
  selectedPlan?: string;
}) => {
  const { classes } = useStyles();
  return (
    <TableRow>
      <TableCell css={classes.stickyColumn} />
      {plans.map((plan) => (
        <PlanCell key={plan.name} plan={plan} selected={plan.name === selectedPlan} />
      ))}
    </TableRow>
  );
};

const PlanCell = ({ plan, selected }: { plan: AccountPlan; selected: boolean }) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <TableCell
      css={[classes.cellTop, classes.cell, selected ? classes.highlightedTopCell : null]}
      align='center'
    >
      <div className='h-mt-xs h-mb-xxs' css={classes.cellTopText}>
        {plan.isPrivate
          ? intl.formatMessage({ id: 'plans.custom' })
          : intl.formatMessage({ id: `plans.${getPlanSlugByName(plan.name)}` })}
        {plan.isCurrent && (
          <span css={classes.currentPending}>
            <br />
            Status: {intl.formatMessage(messages.current)}
          </span>
        )}
        {plan.isPending && (
          <span css={classes.currentPending}>
            <br />
            Status: {intl.formatMessage(messages.pending)}
          </span>
        )}
        {plan.startDate && (
          <p css={classes.startEndDate}>
            <b>{intl.formatMessage(messages.startDate)}</b>{' '}
            {formatDate(new Date(plan.startDate), 'yyyy-MM-dd HH:mm')} UTC
          </p>
        )}
        {plan.endDate && (
          <p css={classes.startEndDate}>
            <b>{intl.formatMessage(messages.endDate)}</b>{' '}
            {formatDate(new Date(plan.endDate), 'yyyy-MM-dd HH:mm')} UTC
          </p>
        )}
      </div>
    </TableCell>
  );
};

const ComparePrices = ({
  plans,
  selectedPlan,
  interval = 'month',
}: {
  plans: AccountPlan[];
  selectedPlan?: string;
  interval?: string;
}) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <TableRow>
      <TableCell css={classes.stickyColumn}>
        <div css={[classes.featureWrapper, classes.priceFeatureWrapper]}>
          <div css={[classes.feature, classes.priceFeature]}>
            {intl.formatMessage({ id: `feature.${interval}.price` })}
          </div>
          {interval === 'year' && (
            <div css={[classes.feature, classes.yearIntervalNote]}>
              <FormattedMessage id='comparePlansTable.paidYearly' defaultMessage='Paid Yearly' />
            </div>
          )}
        </div>
      </TableCell>
      {plans.map((plan) => {
        const selected = plan.name === selectedPlan;
        const { value, units } = plan.features['price'];

        let monthlyPrice = 0;
        if (interval === 'year') {
          monthlyPrice = Number(value) / 100 / 10;
        } else {
          monthlyPrice = Number(value) / 100;
        }

        const yearlyPrice = monthlyPrice * 12;
        const discount = monthlyPrice * 2;
        const price =
          interval === 'year' ? Math.round((yearlyPrice - discount) / 12) : monthlyPrice;

        let _value: any = 'Free';
        if (
          ['enterprise', 'enterprise premium', 'custom'].indexOf(plan.name.toLowerCase()) !== -1
        ) {
          _value = 'Contact Us';
        } else if (price > 0) {
          _value = (
            <FormattedNumber
              // eslint-disable-next-line
              style='currency'
              value={price}
              currency={units}
            />
          );
        }

        return (
          <TableCell
            key={plan.name}
            css={[classes.cell, classes.priceCell, selected ? classes.highlightedCell : null]}
            align='center'
          >
            <span css={classes.boldText}>{_value}</span>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const CompareRows = ({
  plans,
  selectedPlan,
  hasPricing = true,
}: {
  plans: AccountPlan[];
  selectedPlan?: string;
  disabledPlans?: string[];
  hasPricing?: boolean;
}) => {
  const intl = useIntl();
  const { classes } = useStyles();

  // State to store expandable rows
  const [showGroup, setShowGroup] = useState<{ [k: string]: boolean }>({});

  const _onToggleGroup = (feature: string) => {
    setShowGroup({
      ...showGroup,
      [feature]: !showGroup[feature],
    });
  };

  // Filter out features that have group if they are not expanded
  const features = filter(accountFeatures, (feature) => {
    const group = find(FEATURE_GROUPS, (group) => {
      return indexOf(group.features, feature) !== -1;
    });
    return !(!!group && showGroup[group.name] !== true);
  });

  return (
    <Fragment>
      {features.map((feature, index) => {
        if (feature === 'price') {
          return null;
        }

        const group = find(FEATURE_GROUPS, { name: feature });
        const isExpanded = group ? showGroup[group.name] === true : false;

        return (
          <TableRow key={feature}>
            <TableCell css={classes.stickyColumn}>
              {group !== undefined ? (
                <div
                  css={[classes.featureWrapper, classes.clickable]}
                  onClick={_onToggleGroup.bind(null, feature)}
                >
                  <div css={classes.feature}>
                    {intl.formatMessage({ id: `feature.${feature}` })}
                  </div>
                  <Icon
                    css={[classes.arrowIcon, !isExpanded ? classes.arrowRightIcon : null]}
                    name='arrow'
                  />
                </div>
              ) : (
                <div css={classes.featureWrapper}>
                  <div css={classes.feature}>
                    {intl.formatMessage({ id: `feature.${feature}` })}
                  </div>
                  <Tooltip
                    placement='top'
                    title={intl.formatMessage({ id: `feature.${feature}.description` })}
                  >
                    <div css={classes.featureIcon}>
                      <Icon css={classes.helpIcon} name='info' />
                    </div>
                  </Tooltip>
                </div>
              )}
            </TableCell>
            {plans.map((plan) => {
              const selected = plan.name === selectedPlan;
              return (
                <TableCell
                  key={plan.name}
                  style={{ width: plans.length === 1 ? '100%' : 'auto' }}
                  css={[
                    classes.cell,
                    classes.valueCell,
                    selected ? classes.highlightedCell : null,
                    hasPricing === false && features.length === index + 1
                      ? classes.cellNoBorder
                      : null,
                  ]}
                  align='center'
                >
                  <CompareValue feature={plan.features[feature]} selected={selected} />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </Fragment>
  );
};

const CompareValue = ({
  feature,
  selected,
}: {
  feature: AccountFeatureValue;
  selected: boolean;
}) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const { value, units } = feature;
  if (value === true) {
    return (
      <span>
        <Icon css={[classes.check, selected ? classes.highlightCheck : null]} name='check-alt' />
      </span>
    );
  }

  if (value === false || value === 0) {
    return <span>—</span>;
  }

  if (value === -1) {
    return <span>{intl.formatMessage(featureMessages.unlimited)}</span>;
  }

  if (typeof value === 'string') {
    return <span>{intl.formatMessage({ id: value })}</span>;
  }

  return (
    <span>
      {units === 'usd' ? (
        <FormattedNumber
          // eslint-disable-next-line
          style='currency'
          value={value}
          currency={'USD'}
        />
      ) : (
        <Fragment>
          <FormattedNumber value={value} />{' '}
          {units && intl.formatMessage({ id: `feature.${units}` })}
        </Fragment>
      )}
    </span>
  );
};

const CompareSelect = ({
  plans,
  currentPlan,
  processingPlan,
  selectedPlan,
  disabledPlans,
  hasPricing = true,
  onClick,
  onCancelPlanChange,
}: IComparePlansTable) => {
  const { isMaintenanceMode } = useSystem();
  const { classes } = useStyles();
  return (
    <TableRow>
      <TableCell css={classes.stickyColumn} />
      {plans.map((plan) => {
        const selected = plan.name === selectedPlan;
        const current = currentPlan !== undefined && plan.payment_plan_id === currentPlan;
        const processing = processingPlan !== undefined && plan.payment_plan_id === processingPlan;

        let disabled =
          isMaintenanceMode || plan.features['price'].value === undefined || current || !plan.name;

        let showSelector =
          plan.name !== EAccountPlans.community && hasPricing && !current && !processing;

        if (disabledPlans) {
          disabled =
            typeof disabledPlans === 'boolean' ? disabledPlans : disabledPlans.includes(plan.name);

          if (typeof disabledPlans !== 'boolean') {
            showSelector = showSelector === true ? disabledPlans.indexOf(plan.name) === -1 : false;
          }
        }

        return (
          <TableCell
            key={plan.name}
            css={[classes.cellBottom, selected ? classes.highlightedBottomCell : null]}
            align='center'
          >
            {!hasPricing && plan.isPending && onCancelPlanChange && (
              <button onClick={onCancelPlanChange} css={classes.pendingPlan}>
                <FormattedMessage
                  id='comparePlansTable.cancelPlan'
                  defaultMessage='Cancel Plan Change'
                />
              </button>
            )}
            {hasPricing && processing && (
              <div
                css={[classes.planProcessing, selected ? classes.highlightedProcessingPlan : null]}
              >
                <FormattedMessage id='comparePlansTable.processing' defaultMessage='Pending' />
              </div>
            )}
            {hasPricing && current && !processing && (
              <div css={[classes.planCurrent, selected ? classes.highlightedCurrentPlan : null]}>
                <FormattedMessage id='comparePlansTable.current' defaultMessage='Current' />
              </div>
            )}
            {showSelector && (
              <Radio
                className='h-mt-xs h-mb-sm'
                disabled={disabled}
                checked={plan.name === selectedPlan}
                onClick={() => onClick && onClick(plan)}
                css={[classes.planSelect, selected ? classes.planSelectHighlighted : null]}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const useStyles = makeStyles({
  base: {
    wrapper: {
      position: 'relative',
    },
    table: {
      margin: '11px 0px 20px 0px !important',
    },
    stickyColumn: {
      position: 'sticky',
      left: 0,
      zIndex: 1,
      width: '20rem',
      paddingRight: `${styles.spacing.xs} !important`,
    },
    featureWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    priceFeatureWrapper: {
      flexDirection: 'column',
    },
    feature: {
      width: '24rem',
      whiteSpace: 'nowrap',
      fontSize: '1.6rem',
      marginRight: styles.spacing.xxs,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      fontWeight: styles.font.weight.bold,
    },
    featureIcon: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'flex-end',
    },
    priceFeature: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    helpIcon: {
      cursor: 'help',
      fontSize: '0.8rem',
      paddingLeft: '1rem',
      position: 'relative',
      color: styles.color.xLightGrey,
    },
    arrowIcon: {
      fontSize: '0.5rem',
      paddingLeft: '1rem',
      position: 'relative',
      color: styles.color.xLightGrey,
    },
    arrowRightIcon: {
      transform: 'rotate(-90deg)',
      position: 'relative',
      top: '0.6rem',
      left: '0.4rem',
    },
    cell: {
      minWidth: '160px',
      fontSize: `${styles.font.size.p1} !important`,
      borderBottomWidth: '2px !important',
      borderStyle: 'solid !important',
    },
    highlightedCell: {
      color: `${styles.color.white} !important`,
      boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.6)',
    },
    valueCell: {},
    cellTop: {
      borderTopLeftRadius: styles.border.radius,
      borderTopRightRadius: styles.border.radius,
    },
    cellTopText: {
      fontSize: '1.6rem !important',
      fontWeight: styles.font.weight.bold,
      textTransform: 'uppercase',
    },
    highlightedTopCell: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)',
      color: `${styles.color.white} !important`,
    },
    cellNoBorder: {
      borderBottomColor: 'transparent !important',
    },
    cellBottom: {
      borderBottomLeftRadius: styles.border.radius,
      borderBottomRightRadius: styles.border.radius,
    },
    highlightedBottomCell: {
      color: styles.color.white,
      boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.6)',
    },
    check: {
      verticalAlign: 'middle',
      fontSize: '0.4rem',
    },
    highlightCheck: {
      color: styles.color.white,
    },
    boldText: {
      fontWeight: styles.font.weight.bold,
    },
    planSelectHighlighted: {
      color: `${styles.color.white} !important`,
    },
    clickable: {
      cursor: 'pointer',
    },
    pendingPlan: {
      cursor: 'pointer',
      fontWeight: styles.font.weight.bold,
      color: `${styles.color.red} !important`,
    },
    planCurrent: {
      color: `${styles.color.lightGrey} !important`,
    },
    highlightedCurrentPlan: {
      color: `${styles.color.white} !important`,
    },
    planProcessing: {
      color: `${styles.color.lightGrey} !important`,
    },
    highlightedProcessingPlan: {
      color: `${styles.color.white} !important`,
    },
    yearIntervalNote: {
      fontSize: '1.3rem',
      fontWeight: 'normal',
      color: `${styles.color.xLightGrey} !important`,
    },
    startEndDate: {
      fontSize: styles.font.size.p1,
      fontWeight: styles.font.weight.normal,
      textTransform: 'none',
      b: {
        fontWeight: styles.font.weight.bold,
      },
    },
    currentPending: {
      textTransform: 'none',
    },
  },
  light: {
    stickyColumn: {
      backgroundColor: styles.color.white,
    },
    feature: {
      color: styles.color.black,
    },
    cell: {
      borderBottomColor: `${styles.color.xxLightGrey} !important`,
    },
    cellTop: {
      color: `${styles.color.purple} !important`,
    },
    priceCell: {
      color: `${styles.color.purple} !important`,
    },
    priceFeature: {
      color: `${styles.color.purple} !important`,
    },
    check: {
      color: styles.color.purple,
    },
    highlightedCell: {
      backgroundColor: `${styles.color.purple} !important`,
    },
    highlightedTopCell: {
      backgroundColor: `${styles.color.purple} !important`,
    },
    highlightedBottomCell: {
      backgroundColor: `${styles.color.purple} !important`,
    },
    planSelect: {
      color: `${styles.color.purple} !important`,
    },
    startEndDate: {
      color: styles.color.black,
    },
    currentPending: {
      color: styles.color.black,
    },
  },
  dark: {
    stickyColumn: {
      backgroundColor: styles.color.darkOffBlack,
    },
    feature: {
      color: styles.color.lightBlue,
    },
    cell: {
      borderBottomColor: `${styles.border.color.darkPurple} !important`,
    },
    cellTop: {
      color: `${styles.color.lightBlue} !important`,
    },
    check: {
      color: styles.color.lightBlue,
    },
    priceFeature: {
      color: `${styles.color.lightBlue} !important`,
    },
    priceCell: {
      color: `${styles.color.lightBlue} !important`,
    },
    highlightedCell: {
      backgroundColor: `${styles.color.lightBlue} !important`,
    },
    highlightedTopCell: {
      backgroundColor: `${styles.color.lightBlue} !important`,
    },
    highlightedBottomCell: {
      backgroundColor: `${styles.color.lightBlue} !important`,
    },
    planSelect: {
      color: `${styles.color.lightBlue} !important`,
    },
    startEndDate: {
      color: styles.color.xLightGrey,
    },
    currentPending: {
      color: styles.color.xxLightGrey,
    },
  },
});

export default ComparePlansTable;
