import React from 'react';
import { useIntl } from 'react-intl';
import { NormalizedSearchResult } from 'models/Submission';
import metadataMessages from 'views/components/Accordion/AccordionFileDetails/metadataMessages';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import { Row } from 'views/components/table/CustomTable';
import TableCell from '@material-ui/core/TableCell';
import HashSearchTableRow from './HashSearchTableRow';
import {
  CustomTablePagination,
  EPageDirection,
} from 'views/components/table/CustomTablePagination';

interface IHashSearchTable {
  isFirst: boolean;
  isLast: boolean;
  results: NormalizedSearchResult[];
  downloadArtifact: (hash: string) => void;
  viewScanResult: (hash: string) => void;
  rowsPerPage: number;
  onChangePage: (direction: EPageDirection) => void;
  onChangeRowsPerPage: (limit: number) => void;
  onFirstPage: () => void;
}

const rows: Row<NormalizedSearchResult>[] = [
  {
    id: 'hash',
    numeric: false,
    label: metadataMessages.hash.id,
    sortable: false,
  },
  {
    id: 'polyscore',
    numeric: false,
    label: 'polyscore',
    sortable: false,
  },
  {
    id: 'submitted',
    numeric: false,
    label: 'general.scanDate',
    sortable: false,
  },
  {
    id: 'detections',
    numeric: false,
    label: 'general.detections',
    sortable: false,
  },
  {
    id: 'network',
    numeric: false,
    label: 'Network',
    sortable: false,
  },
];

const HashSearchTable = ({
  results,
  viewScanResult,
  downloadArtifact,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  isFirst,
  isLast,
  onFirstPage,
}: IHashSearchTable) => {
  const intl = useIntl();

  return (
    <CustomTable<NormalizedSearchResult>
      rows={results}
      renderTableHead={() => (
        <CustomTableHead
          rows={rows}
          showActions={true}
          renderHeaderCell={(row) => (
            <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
              {intl.formatMessage({ id: row.label })}
            </TableCell>
          )}
        />
      )}
      renderTableRow={(row: NormalizedSearchResult) => (
        <HashSearchTableRow
          key={row.sha256}
          rowData={row}
          viewScanResult={viewScanResult}
          downloadArtifact={downloadArtifact}
        />
      )}
      renderPagination={
        <CustomTablePagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100, 200]}
          onChangePage={onChangePage}
          onFirstPage={onFirstPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          isFirst={isFirst}
          isLast={isLast}
        />
      }
    />
  );
};

export default HashSearchTable;
