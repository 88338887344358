import React, { useState, useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import qs from 'query-string';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { json2csv } from 'json-2-csv';
import { PageQuery } from 'models/Page';
import { Hunt } from 'models/Ruleset';
import api from 'services/api';
import joinUrl from 'utils/joinUrl';
import { RootState } from 'state/root';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { RequestError } from 'utils/error';
import { getErrorMessage } from 'utils/error';
import { getAllHistoricalHunts, getHistoricalHuntResults } from 'state/hunting/actions';
import { getAllLiveHunts } from 'state/hunting/actions';
import { showNotification } from 'state/notification/actions';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import SaveRounded from '@material-ui/icons/SaveRounded';
import Refresh from '@material-ui/icons/Refresh';
import styles from 'views/styles';
import Breadcrumb from 'views/components/layout/Sidebar/Breadcrumb';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import ability from 'config/ability';

const messages = defineMessages({
  deleteIconTitleHistorical: {
    id: 'huntTabs.toolbar.deleteIconTitleHistorical',
    defaultMessage: 'Delete selected hunts and all of their matches',
  },
  deleteIconTitleLive: {
    id: 'huntTabs.toolbar.deleteIconTitleLive',
    defaultMessage: 'Delete selected matches',
  },
  saveIconTitle: {
    id: 'huntTabs.toolbar.saveIconTitle',
    defaultMessage: 'Download list of all matches as CSV',
  },
  refreshHistoricalHunt: {
    id: 'huntTabs.toolbar.refreshHistoricalHunt',
    defaultMessage: 'Refresh Historical Hunt Status',
  },
  refreshLiveHunt: {
    id: 'huntTabs.toolbar.refreshLiveHunt',
    defaultMessage: 'Refresh Matches',
  },
});

const json2csvCallback = (fileName: string) =>
  function (err: Error | undefined, csv: string | undefined) {
    if (err) throw err;
    if (csv) {
      const [oldHeader, ...content] = csv.split('\n');
      const header = oldHeader
        .split(',')
        .map((column: string) => column.replace('.', '_'))
        .join(',');
      const csvContent = [header, ...content].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${format(new Date(), 'yyyyddMM-hhmm')}-${fileName}`);
    }
  };

export const Toolbar = ({
  selectedHunts,
  setSelectedHunts,
}: {
  selectedHunts: string[];
  setSelectedHunts: (hunts: string[]) => void;
}) => {
  const [historicalParams, setHistoricalParams] = useState<PageQuery<Hunt>>({});
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  const { liveHunts } = useSelector((state: RootState) => state.hunting);
  const { historicalHuntResults } = useSelector((state: RootState) => state.hunting);

  const urlParams = qs.parse(location.search);
  const params = { limit: 25, ...urlParams } as PageQuery<Hunt>; // eslint-disable-line react-hooks/exhaustive-deps
  const hunt_id = urlParams.hunt_id;
  const liveHuntUrl = joinUrl(match.url, 'live');
  const isHistoricalUrl = joinUrl(match.url, 'historical');
  const rulesetsUrl = joinUrl(match.url, 'rulesets');
  const isLiveHuntPage = location.pathname === liveHuntUrl;
  const isHistoricalPage = location.pathname === isHistoricalUrl && !hunt_id;
  const isRulesetsPage = location.pathname === rulesetsUrl;
  const scanType = location.pathname === liveHuntUrl ? 'live' : 'historical';
  const hasRulesetAbility =
    ability.can('live_hunt', 'Artifact') || ability.can('historical_hunt', 'Artifact');

  useEffect(() => {
    if (isHistoricalPage && historicalParams.offset !== params.offset) {
      setHistoricalParams(params);
    }
  }, [historicalParams, isHistoricalPage, location.pathname, params]);

  const _showError = (error: RequestError) =>
    dispatch(
      showNotification({
        status: 'failure',
        message: getErrorMessage(error),
        delay: 5000,
      })
    );

  const _refreshHunts = () => {
    if (isLiveHuntPage) {
      dispatch(getAllLiveHunts(true, true, params as any));
    } else if (hunt_id) {
      dispatch(getHistoricalHuntResults(String(hunt_id), params as any));
    } else {
      dispatch(getAllHistoricalHunts(true, params));
    }
  };

  const _deleteHunts = async () => {
    try {
      if (isLiveHuntPage) {
        await api.deleteMultipleHunt(selectedHunts, scanType).then((res) => res.data);
        dispatch(getAllLiveHunts(true, true, params as any));
      } else if (hunt_id) {
        await api.deleteMultipleHuntResult(selectedHunts, scanType).then((res) => res.data);
        dispatch(getHistoricalHuntResults(String(hunt_id), params as any));
      } else {
        await api.deleteMultipleHunt(selectedHunts, scanType).then((res) => res.data);
        dispatch(getAllHistoricalHunts(true, params));
      }
      setSelectedHunts([]);
    } catch (error) {
      _showError(error);
    }
  };

  const _downloadHunts = async () => {
    try {
      const huntResults = isLiveHuntPage ? liveHunts.results : historicalHuntResults?.results;
      const fileName = isLiveHuntPage ? 'Live-matches' : 'Historical-matches';
      if (huntResults && huntResults.length) {
        const results = huntResults
          .filter((hunt: any) => selectedHunts.includes(hunt.id))
          .map((hunt: any) => ({ ...hunt, matched_on: hunt.created }));
        json2csv(results, json2csvCallback(fileName), {
          keys: [
            'sha256',
            'malware_family',
            'polyscore',
            'rule_name',
            'detections.benign',
            'detections.malicious',
            'detections.total',
            'instance_id',
            'matched_on',
          ],
          emptyFieldValue: '',
        });
      }
    } catch (error) {
      _showError(error);
    }
  };

  return hasRulesetAbility ? (
    <div css={classes.container}>
      <div>
        {hunt_id ? (
          <Breadcrumb
            text={'Historical Hunts'}
            onClick={() => {
              history.replace(`${location.pathname}?${qs.stringify(historicalParams)}`);
              setHistoricalParams({});
            }}
            dataCy='huntingBackBtn'
          />
        ) : null}
      </div>
      <div css={classes.buttons}>
        {!isRulesetsPage && !hunt_id && (
          <Tooltip
            title={formatMessage(
              isLiveHuntPage ? messages.refreshLiveHunt : messages.refreshHistoricalHunt
            )}
            placement='top'
          >
            <div css={classes.space}>
              <button css={classes.icon} onClick={_refreshHunts}>
                <Refresh fontSize='small' />
              </button>
            </div>
          </Tooltip>
        )}
        {(isLiveHuntPage || hunt_id) && (
          <Tooltip title={formatMessage(messages.saveIconTitle)} placement='top'>
            <div css={classes.space}>
              <button
                disabled={selectedHunts.length === 0}
                css={classes.icon}
                onClick={_downloadHunts}
              >
                <SaveRounded fontSize='small' />
              </button>
            </div>
          </Tooltip>
        )}
        {!isRulesetsPage && (
          <Tooltip
            title={
              isLiveHuntPage
                ? formatMessage(messages.deleteIconTitleLive)
                : formatMessage(messages.deleteIconTitleHistorical)
            }
            placement='top'
          >
            <div css={classes.space}>
              <button
                disabled={selectedHunts.length === 0}
                css={classes.icon}
                color='secondary'
                onClick={_deleteHunts}
              >
                <DeleteRounded fontSize='small' />
              </button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  ) : null;
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      borderRadius: '50%',
      padding: '.5rem',
      cursor: 'pointer',
      '&[disabled]': {
        cursor: 'not-allowed',
        backgroundColor: styles.color.xLightGrey,
      },
    },
    buttons: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridColumnGap: '1rem',
    },
    noWrap: {
      maxWidth: 'none',
    },
    space: {
      padding: 4,
      margin: 1,
      '&:first-child': {
        marginRight: 0,
      },
    },
  },
  light: {
    icon: {
      backgroundColor: styles.color.purple,
      color: styles.color.white,
    },
  },
  dark: {
    icon: {
      backgroundColor: styles.color.lightBlue,
      color: '#eee',
    },
  },
});
