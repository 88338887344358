import React from 'react'; // eslint-disable-line
import styles from 'views/styles';

export const variants = {
  salmon: {
    border: '#7897FF',
    background: '#E59391',
  },
  forest: {
    border: '#FFAA00',
    background: '#8AC5AA',
  },
  mustard: {
    border: '#FF455B',
    background: '#C2BF72',
  },
  'salmon-dark': {
    border: '#385C92',
    background: '#673D3D',
  },
  'forest-dark': {
    border: '#BF8000',
    background: '#35594A',
  },
  'mustard-dark': {
    border: '#80232E',
    background: '#4F4D0A',
  },
};

export type Variant = keyof typeof variants;

interface IThemePreview {
  variant: Variant;
}

export const ThemePreview = ({ variant }: IThemePreview) => {
  const selectedVariant = variants[variant];
  const isDark = variant.includes('dark');

  return (
    <svg width='199' height='133' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={selectedVariant.background}
        stroke={selectedVariant.border}
        strokeWidth='5'
        d='M2.5 2.625h193.281v126.936H2.5z'
      />
      <path
        fill={isDark ? styles.color.xxDarkPurple : styles.color.offWhite}
        d='M19.602 31.79h159.831v59.56H19.602zM19.602 96.46h159.831v26.387H19.602zM5.136 4.46h188v21h-188z'
      />
      <path
        fill={isDark ? styles.color.offWhite : styles.color.purple}
        d='M12.063 12.942h39.958v6.031H12.063z'
      />
      <path
        fill={isDark ? 'rgba(255, 255, 255, 0.1)' : '#D9D9D9'}
        d='M56.544 12.942h15.832v6.031H56.544zM88.209 43.099h22.618v22.618H88.209zM81.424 101.15h36.188v5.277H81.424zM24.125 116.983H76.9v5.277H24.125zM81.424 116.983h27.141v5.277H81.424zM113.088 116.983h27.141v5.277h-27.141zM144.753 116.983h27.141v5.277h-27.141zM24.125 76.271h150.784v6.031H24.125zM76.9 12.942h15.832v6.031H76.9zM97.256 12.942h15.832v6.031H97.256zM168.124 12.942h15.832v6.031h-15.832z'
      />
    </svg>
  );
};
