import { useMemo } from 'react';
import { getCookieVal } from 'config/config';

// Add new cookie names here
type ValidCookies = 'apiKeysWithScope' | 'manualAnalysisEnabled' | 'limitedAccessEnabled';

enum Visibility {
  Show = '1',
  Hide = '0',
}

export default function useCookie(cookieName: ValidCookies) {
  const enabledCookie = useMemo(() => getCookieVal(cookieName), [cookieName]);

  return {
    show: enabledCookie === Visibility.Show ? true : false,
  };
}
