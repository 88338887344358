import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import session from 'redux-persist/lib/storage/session';
import { History } from 'history';

import user from 'state/user/reducers';
import { UserState } from 'state/user';
import { UserAction } from 'state/user/types';

import submission from 'state/submission/reducers';
import { SubmissionState } from 'state/submission';
import { SubmissionAction } from 'state/submission/types';

// import sandbox from 'state/sandbox/reducers';
import sandbox from 'state/sandbox/reducers';
import { SandboxState } from 'state/sandbox';
import { SandboxAction } from 'state/sandbox/types';

import team from 'state/team/reducers';
import { TeamState } from 'state/team';
import { TeamAction } from 'state/team/types';

import communities from 'state/communities/reducers';
import { CommunitiesState } from 'state/communities';
import { CommunitiesAction } from 'state/communities/types';

import microengines from 'state/microengines/reducers';
import { MicroenginesState } from 'state/microengines';
import { MicroenginesAction } from 'state/microengines/types';

import hunting from 'state/hunting/reducers';
import { HuntingState } from 'state/hunting';
import { HuntingAction } from 'state/hunting/types';

import apiKeys from 'state/apiKeys/reducers';
import { ApiKeysState } from 'state/apiKeys';
import { ApiKeysAction } from 'state/apiKeys/types';

import account from 'state/account/reducers';
import { AccountState } from 'state/account';
import { AccountAction } from 'state/account/types';

import auth from 'state/auth/reducers';
import { AuthState } from 'state/auth';
import { AuthAction } from 'state/auth/types';

import requests from 'state/requests/reducers';
import { RequestsState } from 'state/requests';
import { RequestsAction } from 'state/requests/types';

import notification from 'state/notification/reducers';
import { NotificationState } from 'state/notification';
import { NotificationAction } from 'state/notification/types';

import invitation from 'state/invitation/reducers';
import { InvitationState } from 'state/invitation';
import { InvitationAction } from 'state/invitation/types';

import modal from 'state/modal/reducers';
import { ModalState } from 'state/modal';
import { ModalAction } from 'state/modal/types';

import system from 'state/system/reducers';
import { SystemState } from 'state/system';
import { SystemAction } from 'state/system/types';

import tags from 'state/tags/reducers';
import { TagsState } from 'state/tags';
import { TagsAction } from 'state/tags/types';

import settings from 'state/settings/reducers';
import { SettingsState } from 'models/Settings';
import { SettingsAction } from 'state/settings/types';

import plans from 'state/plans/reducers';
import { PlansState } from 'state/plans';
import { PlansAction } from 'state/plans/types';

import webhooks from 'state/webhooks/reducers';
import { WebhooksState } from 'state/webhooks';
import { WebhooksAction } from 'state/webhooks/types';

export const userPersistConfig = {
  key: 'user',
  whitelist: ['profile'],
  storage,
};

export const accountPersistConfig = {
  key: 'account',
  whitelist: ['context'],
  storage: session,
};

export const authPersistConfig = {
  key: 'auth',
  storage,
};

const localePersistConfig = {
  key: 'locale',
  storage,
};

export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    user: persistReducer(userPersistConfig, user),
    sandbox,
    submission,
    team,
    communities,
    microengines,
    hunting,
    apiKeys,
    account: persistReducer(accountPersistConfig, account),
    auth: persistReducer(authPersistConfig, auth),
    requests,
    notification,
    invitation,
    modal,
    system,
    tags,
    settings: persistReducer(localePersistConfig, settings),
    plans,
    webhooks,
  });

export type RootState = {
  user: UserState & { _persist: any };
  sandbox: SandboxState;
  submission: SubmissionState;
  team: TeamState;
  communities: CommunitiesState;
  microengines: MicroenginesState;
  hunting: HuntingState;
  apiKeys: ApiKeysState;
  webhooks: WebhooksState;
  account: AccountState & { _persist: any };
  auth: AuthState & { _persist: any };
  requests: RequestsState;
  notification: NotificationState;
  invitation: InvitationState;
  modal: ModalState;
  system: SystemState;
  tags: TagsState;
  settings: SettingsState & { _persist: any };
  plans: PlansState;
};

export type RootAction =
  | UserAction
  | SandboxAction
  | SubmissionAction
  | TeamAction
  | CommunitiesAction
  | MicroenginesAction
  | HuntingAction
  | ApiKeysAction
  | AccountAction
  | AuthAction
  | NotificationAction
  | InvitationAction
  | RequestsAction
  | ModalAction
  | SystemAction
  | TagsAction
  | SettingsAction
  | PlansAction
  | WebhooksAction;
