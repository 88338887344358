import React, { Fragment } from 'react';
import { Team, TeamMember } from 'models/Team';
import { RootState } from 'state/root';
import { useSelector, useDispatch } from 'react-redux';
import { Account } from 'models/Account';
import { openModal } from 'state/modal/actions';
import Can from 'views/components/auth/Can';
import PageSidebarLayout from 'views/components/layout/PageSidebarLayout';
import { AccountPlan } from 'views/components/account/AccountPlan';
import TeamDetailSidebar from './TeamDetailSidebar';
import TeamDetailPanel from './TeamDetailPanel';

interface ITeamDetailLayout {
  team: Team;
  allMembers: TeamMember[];
  account: Account;
  handleUpgrade: () => void;
}

const TeamDetailLayout = ({ team, account, allMembers, handleUpgrade }: ITeamDetailLayout) => {
  const dispatch = useDispatch();
  const community = useSelector((state: RootState) => state.communities.single);

  return (
    <PageSidebarLayout
      renderSidebar={() => (
        <Fragment>
          <TeamDetailSidebar
            className='h-mb-grid'
            team={team}
            community={community}
            members={allMembers}
            onTeamEdit={() => dispatch(openModal('UPDATE_TEAM'))}
            onTeamArchive={() => dispatch(openModal('ARCHIVE_TEAM'))}
          />
          <Can I='manage' a='TeamBilling'>
            <AccountPlan
              accountPlanName={account.plan.name}
              usagePeriodStart={account.windowStart}
              usagePeriodEnd={account.windowEnd}
              subscriptionStart={account.planPeriodStart}
              subscriptionEnd={account.planPeriodEnd}
              onClick={handleUpgrade}
            />
          </Can>
        </Fragment>
      )}
      renderPanel={() => <TeamDetailPanel />}
    />
  );
};

export default TeamDetailLayout;
