import React, { Fragment, forwardRef } from 'react'; // eslint-disable-line
import { jsx, ObjectInterpolation } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles as makeMuiStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { TeamAvatar, UserAvatar } from 'views/components/Avatar';
import { MenuOption } from './UserMenu';

type Props = Partial<MenuOption>;

const UserMenuItem = forwardRef<any, Props>((props, ref) => {
  const { href, onClick, spacer, dataCy } = props;
  const { classes } = useStyles(spacer);
  const muiClasses = useMuiStyles();

  return (
    <Fragment>
      {href ? (
        <MenuItem
          data-cy={dataCy}
          component='a'
          ref={ref}
          href={href}
          target='_blank'
          rel='noopener noreferrer'
          css={classes.menuItem}
          classes={{ root: muiClasses.item }}
        >
          <UserMenuItemInner {...props} />
        </MenuItem>
      ) : (
        <MenuItem
          data-cy={dataCy}
          ref={ref}
          css={classes.menuItem}
          classes={{ root: muiClasses.item }}
          onClick={onClick}
        >
          <UserMenuItemInner {...props} />
        </MenuItem>
      )}
    </Fragment>
  );
});

const UserMenuItemInner = ({ iconName, teamName, username, label, spacer, isActive }: Props) => {
  const { classes } = useStyles(spacer);
  return (
    <div css={classes.item}>
      {(iconName || teamName || username) && (
        <div css={classes.iconWrap}>
          {iconName && <Icon css={classes.icon} name={iconName} />}
          {teamName && <TeamAvatar css={classes.avatar} teamName={teamName} />}
          {username && <UserAvatar css={classes.avatar} username={username} />}
        </div>
      )}
      <div css={classes.container}>
        <span>{username || teamName || label}</span>
        {isActive && <Icon name='check-alt' css={classes.check} />}
      </div>
    </div>
  );
};

const spacerStyles = (color: string): ObjectInterpolation<any> => ({
  marginBottom: '1.6rem !important',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-0.8rem',
    left: 0,
    display: 'block',
    height: 1,
    width: '100%',
    backgroundColor: color,
  },
});

const useStyles = makeStyles((spacer?: boolean) => ({
  base: {
    item: {
      height: '3.2rem',
      display: 'flex',
      alignItems: 'center',
      minWidth: '12rem',
      padding: '2rem 1rem',
      width: '100%',
    },
    iconWrap: {
      display: 'flex',
      marginRight: '1.2rem',
    },
    icon: {
      width: 24,
      height: '3.2em',
    },
    avatar: {
      height: '2.5em',
      width: '2.5em',
      img: {
        left: '0.1em',
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      gap: '1.2rem',
    },
    check: {
      width: '1.5rem !important',
    },
  },
  light: {
    iconWrap: {
      color: styles.color.purple,
    },
    menuItem: spacer ? spacerStyles(styles.color.xxxLightGrey) : {},
    check: {
      color: styles.color.purple,
    },
  },
  dark: {
    iconWrap: {
      color: styles.color.lightBlue,
    },
    menuItem: spacer ? spacerStyles(styles.border.color.darkPurple) : {},
    check: {
      color: styles.color.lightBlue,
    },
  },
}));

const useMuiStyles = makeMuiStyles({
  item: {
    fontSize: styles.font.size.p1,
    transition: `color ${styles.easing.time} ${styles.easing.main}`,
    overflow: 'visible',
  },
});

export default UserMenuItem;
