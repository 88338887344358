import { sortBy } from 'lodash';
import { AccountFeatureSlug, AccountUsage } from 'models/Account';
import { AccountFeature } from 'models/Account';
import { AccountRes } from 'services/api/schema/account';

export const featureMap: { [k: string]: AccountFeatureSlug } = {
  scan: 'scans',
  hash_search: 'hashSearches',
  daily_api_limit: 'apiRequestLimit',
  historical_hunt: 'historicalHunts',
  download: 'downloads',
  live_hunt: 'liveHunts',
  metadata_search: 'metadataSearches',
  price: 'price',
  has_support: 'hasSupport',
  private_comm: 'privateComm',
  create_teams: 'createTeams',
  sandbox_request: 'sandboxRequest',
  sandbox_search: 'sandboxSearch',
};

export const transformUsageData = (account: AccountRes): AccountUsage[] => {
  return sortBy(transformFeature(account.features), 'feature');
};

export const transformFeature = (features: AccountFeature[] = []): AccountUsage[] => {
  return features
    .filter((feature) => featureMap[feature.tag] !== undefined)
    .map((feature) => ({
      ...feature,
      baseUses: (feature.value ? Infinity : feature.baseUses) as number,
    }))
    .map((feature) => ({
      feature: featureMap[feature.tag],
      tag: feature.tag,
      baseQuota: feature.baseUses,
      overage: feature.overage,
      remainingUses: feature.remainingUses,
      numUses: feature.baseUses - feature.remainingUses,
      totalAvail: feature.baseUses,
      value: feature.value,
    }));
};

const snakeToCamel = (str: string) => {
  return str.replace(/_([a-z])/g, (match) => {
    return match[1].toUpperCase();
  });
};

export const transformFeatureUsage = (features: AccountFeature[]): AccountUsage[] => {
  return features
    .map((feature) => ({
      ...feature,
      baseQuota: (feature.value ? Infinity : feature.baseUses) as number,
    }))
    .map((feature) => ({
      feature: featureMap[feature.tag] || (snakeToCamel(feature.tag) as AccountFeatureSlug),
      tag: feature.tag,
      baseQuota: feature.baseQuota,
      baseUses: feature.baseUses,
      overage: feature.overage,
      remainingUses: feature.remainingUses,
      numUses: feature.baseQuota - feature.remainingUses,
      totalAvail: feature.baseQuota,
      value: feature.value,
    }));
};
