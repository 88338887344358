import { useCallback, useEffect, useState } from 'react';
import {
  Sandboxing,
  filterMapper,
  filterIgnore,
  invertedStatus,
  StatusValues,
} from 'models/Sandboxing';
import api from 'services/api';
import { SandboxProviders, SandboxInstanceStatus, Sandbox, SandboxConfig } from 'models/Sandbox';
import { getErrorMessage } from 'utils/error';
import { toISODateTime, toISODate, daysFromNowDate } from 'utils/date/date';

type Filter = {
  key: string;
  value: string;
};
type Days = 'TODAY' | (string & {});

function daysToDate(days: Days) {
  const today = toISODate(new Date());
  if (days.toUpperCase() === 'TODAY') {
    return today;
  }
  const daysNumber = parseInt(days.replace(' days', ''), 10);
  return toISODate(daysFromNowDate(-daysNumber));
}

function mapFilters(jsonItems: Filter[]) {
  const dict: { [key: string]: string } = {};

  for (const item of jsonItems) {
    if (filterIgnore.includes(item.key)) continue;
    if (item.value === '') continue;
    if (['Any', 'All', 'Anyone'].includes(item.value)) continue;

    const mappedKey = filterMapper[item.key as keyof typeof filterMapper] || item.key;
    if (mappedKey === 'status') {
      dict[mappedKey] = invertedStatus[item.value as StatusValues];
    } else if (['startDate', 'endDate'].includes(mappedKey)) {
      dict[mappedKey] = daysToDate(item.value as Days);
    } else {
      const lowercasedValue = item.value.toLowerCase();
      dict[mappedKey] = lowercasedValue;
    }
  }

  return dict;
}

const getSandboxScore = (sandboxConfig: Sandbox<SandboxConfig>['config']) => {
  const sandboxProvider: SandboxProviders =
    SandboxProviders[sandboxConfig?.provider?.name.toUpperCase() as keyof typeof SandboxProviders];

  switch (sandboxProvider) {
    case SandboxProviders.CAPE: {
      return `${Math.round(sandboxConfig?.capeMalscore ?? 0)}`;
    }
    case SandboxProviders.TRIAGE: {
      return `${sandboxConfig?.traigeAnalysisScore ?? 'N/A'}`;
    }
    default: {
      return '0';
    }
  }
};

function useAllSandboxing(filters: Filter[], shouldFetch: boolean) {
  const [latestFilters, setLatestFilters] = useState<Filter[]>(filters);
  const [stringifiedFilters, setStringifiedFilters] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);
  const [access, setAccess] = useState<boolean>(shouldFetch);
  const [data, setData] = useState<Sandboxing[]>([]);
  const [firstFetch, setFirstFetch] = useState<boolean>(false);
  const [nextOffset, setNextOffset] = useState<string | null>(null);

  const refetchItem = useCallback(
    async (sha256: string, id: string) => {
      try {
        const { data: item } = await api.getSandboxTaskByHashAndId(sha256, id);
        const index = data.findIndex((item) => item.id === id);
        if (index !== -1) {
          const newData = [...data];
          newData[index] = {
            ...newData[index],
            status: item.status as SandboxInstanceStatus,
          } as Sandboxing;

          setData(newData);
        }
      } catch (error) {
        const errorMessage = getErrorMessage(error as Error);
        setError(errorMessage);
      }
    },
    [data]
  );

  const _setAccess = useCallback((access: boolean) => setAccess(true), []);

  const fetchData = useCallback(
    async (filters: Filter[], fetchOffset = true) => {
      if (!filters.find((item) => item.key === 'sha256' && !!item.value)) return;
      try {
        setError(false);
        setLoading(true);

        const { status: statusRes, data: newData } = await api.allSandboxing(
          Object.assign(mapFilters(filters), fetchOffset && !!nextOffset && { offset: nextOffset })
        );

        if (statusRes === 200) {
          const newResults = newData.result.map((item: any) => {
            return {
              id: `${item.id}`,
              instanceId: `${item.instanceId}`,
              type: item.config?.artifactType ?? 'FILE',
              target: item.artifact?.filename ?? item.config?.target,
              sandboxOn: toISODateTime(item.created),
              sha256: item.sha256,
              score: getSandboxScore(item?.config),
              providerName: item.sandbox,
              sandboxProvider: item.config?.vm?.name
                ? item.sandbox + ' - ' + item.config.vm.name
                : item.sandbox,
              status: item.status as SandboxInstanceStatus,
            };
          });

          const stringFilters = JSON.stringify(filters);
          if (stringifiedFilters !== stringFilters) {
            setData(newResults);
            setStringifiedFilters(stringFilters);
            setNextOffset(null);
          } else if (fetchOffset) {
            setData(data.concat(newResults));
          } else {
            setData(newResults);
          }
        } else {
          throw new Error('Unexpected response from server');
        }

        setNextOffset(newData.offset || null);
      } catch (error) {
        const err = error as { response: { status: number } };
        if (err.response?.status === 404) {
          setData([]);
        } else {
          const errorMessage = getErrorMessage(error as Error);
          setData([]);
          setError(errorMessage);
        }
      } finally {
        setLoading(false);
      }
    },
    [nextOffset, data, stringifiedFilters]
  );

  const refetch = useCallback(
    async (filters?: Filter[], fetchOffset = true, isReset?: boolean) => {
      if (isReset) {
        setData([]);
        setFirstFetch(false);
        setLatestFilters([]);
        setStringifiedFilters('');
        setNextOffset(null);
        setError(false);
      }

      if (shouldFetch) {
        if (filters) {
          if (JSON.stringify(filters) !== JSON.stringify(latestFilters)) {
            await fetchData(filters, fetchOffset);
          }
          setLatestFilters(filters);
        } else {
          await fetchData(latestFilters, fetchOffset);
        }
      }
    },
    [shouldFetch, fetchData, latestFilters]
  );

  useEffect(() => {
    if (!!filters?.length && !firstFetch && !loading && access) {
      setFirstFetch(true);
      fetchData(filters);
    }
  }, [firstFetch, loading, filters, access, fetchData]);

  return {
    refetch,
    refetchItem,
    more: !!nextOffset,
    loading,
    error,
    data,
    setAccess: _setAccess,
    firstFetch,
  };
}
export default useAllSandboxing;
