import { AsyncAction } from 'state/types/actions';
import { CommunitiesRes, CommunityRes, ExpertsRes } from 'services/api/schema/communities';

export enum CommunitiesActionName {
  GET_ALL_COMMUNITIES = 'GET_ALL_COMMUNITIES',
  GET_COMMUNITY = 'GET_COMMUNITY',
  GET_EXPERTS = 'GET_EXPERTS',
}

export interface GetAllCommunitiesAction
  extends AsyncAction<CommunitiesActionName.GET_ALL_COMMUNITIES, CommunitiesRes> {}

export interface GetCommunityAction
  extends AsyncAction<CommunitiesActionName.GET_COMMUNITY, CommunityRes> {}

export interface GetExpertsAction
  extends AsyncAction<CommunitiesActionName.GET_EXPERTS, ExpertsRes> {}

export type CommunitiesAction = GetAllCommunitiesAction | GetCommunityAction | GetExpertsAction;
