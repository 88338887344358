import React from 'react';
import { Field, FieldProps } from 'formik';
import TagInput, { ITagInputProps } from 'views/components/Tag/TagInput';

export interface IFormTagsInputItem {
  title?: string;
  value: string;
}

export interface IFormTagsInputProps extends ITagInputProps {
  errorOnTouched?: boolean;
}

const FormTagsInput = ({ name, errorOnTouched, ...rest }: IFormTagsInputProps) => (
  <Field name={name}>
    {({ form, field, meta }: FieldProps) => {
      const error = meta.error;
      let isError = !!error;
      if (errorOnTouched) isError = !!meta.touched && isError;

      return (
        <TagInput
          {...rest}
          name={field.name}
          value={field.value}
          error={isError}
          helperText={isError ? error : ''}
          onChange={(newValue: string[]) => {
            form.setFieldValue(field.name, newValue);
          }}
        />
      );
    }}
  </Field>
);

export default FormTagsInput;
