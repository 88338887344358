import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import WebhooksTable from 'views/components/table/WebhooksTable/WebhooksTableContainer';

const TeamWebhooksTab = () => {
  const teamId = useSelector((state: RootState) => state.team.single!.id);
  return <WebhooksTable teamId={teamId} />;
};

export default TeamWebhooksTab;
