import { AsyncAction } from 'state/types/actions';
import { Action } from 'redux';
import {
  AccountPlansRes,
  AccountContextRes,
  NotificationSettingRes,
  NotificationSettingsRes,
  PaymentMethodsRes,
  SubscriptionTransactionsRes,
  PaymentMethodRes,
} from 'services/api/schema/account';
import { IPaymentPlans } from 'models/Account';

export enum AccountActionName {
  GET_ACCOUNT_PLANS = 'GET_ACCOUNT_PLANS',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  GET_ACCOUNT_CONTEXT = 'GET_ACCOUNT_CONTEXT',
  ENABLE_NOTIFICATIONS = 'ENABLE_NOTIFICATIONS',
  SET_NOTIFICATION_SETTING = 'SET_NOTIFICATION_SETTING',
  UPDATE_NOTIFICATION_SETTING = 'UPDATE_NOTIFICATION_SETTING',
  UPDATE_ALL_NOTIFICATION_SETTINGS = 'UPDATE_ALL_NOTIFICATION_SETTINGS',
  GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS',
  REFRESH_NOTIFICATION_SETTINGS = 'REFRESH_NOTIFICATION_SETTINGS',
  RESET_NOTIFICATION_SETTINGS = 'RESET_NOTIFICATION_SETTINGS',
  CANCEL_PENDING_PLAN = 'CANCEL_PENDING_PLAN',
  GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS',
  ATTACH_PAYMENT_METHOD = 'ATTACH_PAYMENT_METHOD',
  DETACH_PAYMENT_METHOD = 'DETACH_PAYMENT_METHOD',
  SET_DEFAULT_PAYMENT_METHOD = 'SET_DEFAULT_PAYMENT_METHOD',
  GET_PAYMENT_PLANS = 'GET_PAYMENT_PLANS',
  GET_SUBSCRIPTION_TRANSACTIONS = 'GET_SUBSCRIPTION_TRANSACTIONS',
}

export interface GetAccountPlansAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_PLANS, AccountPlansRes> {}

export interface SwitchAccountAction
  extends AsyncAction<AccountActionName.SWITCH_ACCOUNT, AccountContextRes> {}

export interface GetAccountContextAction
  extends AsyncAction<AccountActionName.GET_ACCOUNT_CONTEXT, AccountContextRes> {}

export interface EnableNotificationsAction
  extends AsyncAction<AccountActionName.ENABLE_NOTIFICATIONS, {}> {}

export interface SetNotificationSettingAction
  extends Action<AccountActionName.SET_NOTIFICATION_SETTING> {
  setting: NotificationSettingRes;
  isEnabled: boolean;
}

export interface UpdateNotificationSettingAction
  extends AsyncAction<AccountActionName.UPDATE_NOTIFICATION_SETTING, NotificationSettingRes> {}

export interface UpdateAllNotificationSettingsAction
  extends Action<AccountActionName.UPDATE_ALL_NOTIFICATION_SETTINGS> {
  isEnabled: boolean;
}

export interface GetNotificationSettingsAction
  extends AsyncAction<
    AccountActionName.GET_NOTIFICATION_SETTINGS,
    NotificationSettingsRes['notification_settings']
  > {}

export interface RefreshNotificationSettingsAction
  extends AsyncAction<
    AccountActionName.REFRESH_NOTIFICATION_SETTINGS,
    NotificationSettingsRes['notification_settings']
  > {}

export interface ResetNotificationSettingsAction
  extends Action<AccountActionName.RESET_NOTIFICATION_SETTINGS> {}

export interface CancelPendingPlanAction
  extends AsyncAction<AccountActionName.CANCEL_PENDING_PLAN, { message: string }> {}

export interface AttachPaymentMethodAction
  extends AsyncAction<AccountActionName.ATTACH_PAYMENT_METHOD, PaymentMethodRes> {}

export interface DetachPaymentMethodAction extends Action<AccountActionName.DETACH_PAYMENT_METHOD> {
  id: string;
}

export interface SetDefaultPaymentMethodAction
  extends Action<AccountActionName.SET_DEFAULT_PAYMENT_METHOD> {
  id: string;
}

export interface GetPaymentMethodsAction
  extends AsyncAction<AccountActionName.GET_PAYMENT_METHODS, PaymentMethodsRes> {}

export interface GetSubscriptionTransactionsAction
  extends AsyncAction<
    AccountActionName.GET_SUBSCRIPTION_TRANSACTIONS,
    SubscriptionTransactionsRes
  > {}

export interface GetPaymentPlansAction extends Action<AccountActionName.GET_PAYMENT_PLANS> {
  paymentPlans: IPaymentPlans;
}

export type AccountAction =
  | GetAccountPlansAction
  | SwitchAccountAction
  | GetAccountContextAction
  | EnableNotificationsAction
  | SetNotificationSettingAction
  | UpdateNotificationSettingAction
  | UpdateAllNotificationSettingsAction
  | GetNotificationSettingsAction
  | RefreshNotificationSettingsAction
  | ResetNotificationSettingsAction
  | CancelPendingPlanAction
  | GetPaymentMethodsAction
  | GetPaymentPlansAction
  | GetSubscriptionTransactionsAction
  | AttachPaymentMethodAction
  | DetachPaymentMethodAction
  | SetDefaultPaymentMethodAction;
