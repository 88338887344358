import { useState } from 'react';
import { getErrorMessage, getErrorStatus } from 'utils/error';
import { format } from 'date-fns';
import api from 'services/api';
import { TelemetrySummaryRes } from 'services/api/schema/wallets';
import { getBalanceValues } from 'utils/balance';

interface DateProps {
  start?: Date | null;
  end?: Date | null;
}

export interface RewardsSummaryRes extends TelemetrySummaryRes {
  truncatedAmount: string;
  fullAmount: string;
}

export interface UseTelemetrySummary {
  rewardsSummary: Partial<RewardsSummaryRes>;
  loading: boolean;
  error: number;
  errorMessage: string | undefined;
  getRewardsSummary: (props: DateProps) => Promise<void>;
  refetch: () => void;
}

export const useRewardsSummary = (): UseTelemetrySummary => {
  const [rewardsSummary, setRewardsSummary] = useState<Partial<RewardsSummaryRes>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const getRewardsSummaryApi = async ({ start, end }: DateProps): Promise<void> => {
    const response = await api.getRewardsSummary({
      start: start ? format(start, 'yyyy-MM-dd') : undefined,
      end: end ? format(end, 'yyyy-MM-dd') : undefined,
    });
    const data = response.data;
    const [truncatedAmount, fullAmount] = getBalanceValues(parseInt(data.amount, 10));
    setRewardsSummary({
      ...data,
      truncatedAmount,
      fullAmount,
    });
  };

  const refetch = () => setError(0);

  async function getRewardsSummary({ start, end }: DateProps) {
    setError(0);
    setLoading(true);
    try {
      await getRewardsSummaryApi({ start, end });
    } catch (error) {
      setError(getErrorStatus(error) || 500);
      setErrorMessage(getErrorMessage(error));
    }
    setLoading(false);
  }

  return {
    rewardsSummary,
    loading,
    error,
    errorMessage,
    getRewardsSummary,
    refetch,
  };
};
