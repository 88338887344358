import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useUser } from 'views/components/providers/UserProvider';
import { useTheme } from 'views/components/providers/ThemeProvider';
import { Variant, variants } from 'views/components/ThemePreview';
import { showNotification } from 'state/notification/actions';
import { RootState } from 'state/root';
import { getTeamAccount, getTeamByAccount } from 'state/team/actions';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

import pattern from 'assets/images/pattern.svg';
import api from 'services/api';

const applyTheme = (variant: Variant, theme: 'light' | 'dark') => {
  document.documentElement.style.setProperty(
    '--community-background',
    variants[theme === 'light' ? variant : (`${variant}-dark` as Variant)].background
  );
  document.documentElement.style.setProperty('--community-background-image', `url(${pattern})`);
};

export default function usePrivateCommunityTheme() {
  const theme = useTheme();
  const user = useUser();
  const ctx = contextAccount(store);
  const { single: team } = useSelector(({ team }: RootState) => team);
  const [variant, setVariant] = useState<Variant>();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getTeamData() {
      if (ctx?.context === 'team' && String(ctx?.accountNumber) !== String(team?.accountNumber)) {
        await dispatch(getTeamByAccount(ctx?.accountNumber));
        dispatch(getTeamAccount(ctx.accountNumber));
      }
    }
    getTeamData();
  }, [dispatch, ctx?.context, ctx?.accountNumber, user.context, team?.accountNumber]);

  useEffect(() => {
    const isPrivateCommunity = user.context?.isPrivate;

    async function getTeamTheme() {
      const { data } = await api.getTeamByAccount(team?.accountNumber!);
      const teamPrivateCommunityTheme = data.theme ? (data.theme as Variant) : 'salmon';
      setVariant(teamPrivateCommunityTheme);
      applyTheme(teamPrivateCommunityTheme, theme);
    }

    if (team?.accountNumber && isPrivateCommunity) {
      getTeamTheme();
    }
  }, [team?.accountNumber, theme, user.context?.isPrivate]);

  return {
    variant: variant || team?.theme,
    theme: theme,
    setVariant: async (variant: Variant) => {
      const teamAccountNumber = team?.accountNumber;
      if (teamAccountNumber && team?.name) {
        try {
          await api.setTeamTheme(teamAccountNumber, variant);
          setVariant(variant);
          applyTheme(variant, theme);
          dispatch(
            showNotification({
              status: 'success',
              message: `Private mode theme has been updated for all ${team.name} members`,
              delay: 1000 * 10,
            })
          );
        } catch (e) {
          dispatch(
            showNotification({
              status: 'failure',
              message: 'Failed to update theme. Please try again later.',
              delay: 1000 * 10,
            })
          );
        }
      }
    },
  };
}
