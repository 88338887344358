import { AsyncAction } from 'state/types/actions';
import {
  TeamRes,
  TeamMemberRes,
  TeamMembersRes,
  TeamMemberInvitationRes,
  TeamMemberInvitationsRes,
} from 'services/api/schema/team';
import { AccountRes } from 'services/api/schema/account';

export enum TeamActionName {
  CREATE_TEAM = 'CREATE_TEAM',
  GET_TEAM = 'GET_TEAM',
  UPDATE_TEAM = 'UPDATE_TEAM',
  ARCHIVE_TEAM = 'ARCHIVE_TEAM',
  GET_ALL_TEAM_MEMBERS = 'GET_ALL_TEAM_MEMBERS',
  REFRESH_TEAM_MEMBERS = 'REFRESH_TEAM_MEMBERS',
  INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER',
  LEAVE_TEAM = 'LEAVE_TEAM',
  ARCHIVE_TEAM_MEMBER = 'ARCHIVE_TEAM_MEMBER',
  REFRESH_TEAM_INVITATIONS = 'REFRESH_TEAM_INVITATIONS',
  GET_TEAM_INVITATIONS = 'GET_TEAM_INVITATIONS',
  ARCHIVE_TEAM_INVITATION = 'ARCHIVE_TEAM_INVITATION',
  RESEND_TEAM_INVITATION = 'RESEND_TEAM_INVITATION',
  GET_TEAM_ACCOUNT = 'GET_TEAM_ACCOUNT',
  GET_TEAM_USAGE = 'GET_TEAM_USAGE',
  GET_TEAM_COMMUNITIES = 'GET_TEAM_COMMUNITIES',
}

export interface CreateTeamAction extends AsyncAction<TeamActionName.CREATE_TEAM, TeamRes> {}

export interface GetTeamAction extends AsyncAction<TeamActionName.GET_TEAM, TeamRes> {}

export interface UpdateTeamAction extends AsyncAction<TeamActionName.UPDATE_TEAM, TeamRes> {}

export interface ArchiveTeamAction extends AsyncAction<TeamActionName.ARCHIVE_TEAM, TeamRes> {}

export interface GetAllTeamMembersAction
  extends AsyncAction<TeamActionName.GET_ALL_TEAM_MEMBERS, TeamMembersRes> {}

export interface RefreshTeamMembersAction
  extends AsyncAction<TeamActionName.REFRESH_TEAM_MEMBERS, TeamMembersRes> {}

export interface InviteTeamMemberAction
  extends AsyncAction<TeamActionName.INVITE_TEAM_MEMBER, TeamMemberInvitationRes> {}

export interface UpdateTeamMemberAction
  extends AsyncAction<TeamActionName.UPDATE_TEAM_MEMBER, TeamMemberRes> {}

export interface LeaveTeamAction extends AsyncAction<TeamActionName.LEAVE_TEAM, TeamMemberRes> {}

export interface ArchiveTeamMemberAction
  extends AsyncAction<TeamActionName.ARCHIVE_TEAM_MEMBER, TeamMemberRes> {}

export interface RefreshTeamInvitationsAction
  extends AsyncAction<TeamActionName.REFRESH_TEAM_INVITATIONS, TeamMemberInvitationsRes> {}

export interface GetTeamInvitationsAction
  extends AsyncAction<TeamActionName.GET_TEAM_INVITATIONS, TeamMemberInvitationsRes> {}

export interface ArchiveTeamInvitationAction
  extends AsyncAction<TeamActionName.ARCHIVE_TEAM_INVITATION, TeamMemberInvitationRes> {}

export interface ResendTeamInvitationAction
  extends AsyncAction<TeamActionName.RESEND_TEAM_INVITATION, TeamMemberInvitationRes> {}

export interface GetTeamAccountAction
  extends AsyncAction<TeamActionName.GET_TEAM_ACCOUNT, AccountRes> {}

export interface GetTeamUsageAction
  extends AsyncAction<TeamActionName.GET_TEAM_USAGE, AccountRes> {}

export interface GetTeamCommunitiesAction
  extends AsyncAction<TeamActionName.GET_TEAM_COMMUNITIES, string[]> {}

export type TeamAction =
  | CreateTeamAction
  | GetTeamAction
  | UpdateTeamAction
  | ArchiveTeamAction
  | GetAllTeamMembersAction
  | RefreshTeamMembersAction
  | InviteTeamMemberAction
  | UpdateTeamMemberAction
  | LeaveTeamAction
  | ArchiveTeamMemberAction
  | RefreshTeamInvitationsAction
  | GetTeamInvitationsAction
  | ArchiveTeamInvitationAction
  | ResendTeamInvitationAction
  | GetTeamAccountAction
  | GetTeamUsageAction
  | GetTeamCommunitiesAction;
