import api from 'services/api';
import numeral from 'numeral';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RequestError } from 'utils/error';
import { getErrorMessage } from 'utils/error';
import { Dispatch } from 'state/types/thunk';
import { showNotification } from 'state/notification/actions';
import { useUser } from 'views/components/providers/UserProvider';
import { featureMap } from 'state/utils/usage';

const formatValue = (value: string | number) =>
  Number.isInteger(value as number) ? numeral(value).format('0,0') : value;

export default function useSetOverage() {
  const user = useUser();
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const accountNumber = user.context?.accountNumber || user.accountNumber;

  const setOverage = async (featureTag: string, overage: number) => {
    try {
      await api.setFeatureOverage(accountNumber, featureTag, overage);
      dispatch(
        showNotification({
          status: 'success',
          message: `Overage quota updated to ${formatValue(overage)} for ${intl.formatMessage({
            id: `feature.${featureMap[featureTag]}`,
          })}`,
          delay: 5000,
          isDismissible: true,
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          status: 'failure',
          message: getErrorMessage(error as RequestError),
          delay: 5000,
          isDismissible: true,
        })
      );
    }
  };

  return { setOverage };
}
