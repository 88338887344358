import React, { Fragment } from 'react';
import { Global, css } from '@emotion/core';

export default () => (
  <Fragment>
    <symbol id='icon-menu' viewBox='0 0 32 20'>
      <line x1='2' y1='2' x2='30' y2='2' stroke-width='4' stroke-linecap='round' />
      <line x1='2' y1='10' x2='30' y2='10' stroke-width='4' stroke-linecap='round' />
      <line x1='2' y1='18' x2='30' y2='18' stroke-width='4' stroke-linecap='round' />
    </symbol>

    <Global
      styles={css`
        .icon-menu {
          width: 3.2em;
          height: 2em;
        }
      `}
    />
  </Fragment>
);
