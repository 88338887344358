import React, { useState, useEffect } from 'react'; // eslint-disable-line
import qs from 'query-string';
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import api from 'services/api';
import styles from 'views/styles';
import { ModalState } from 'state/modal';
import { translateError, ErrorKeys, RequestError } from 'utils/error';
import { ScanType } from 'models/Ruleset';
import { closeModal } from 'state/modal/actions';
import Modal from 'views/components/Modal';
import ViewRulesetForm from '../forms/ViewRulesetForm';

export interface ViewRulesetModalParams {
  id: string;
  scanType: ScanType;
}

const errorKeys: ErrorKeys = {};

const ViewRulesetModal = () => {
  const [ruleset, setRuleset] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<RequestError | undefined>();

  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const { hunt_id } = qs.parse(location.search);

  const { id, scanType } = useSelector(
    ({ modal }: { modal: ModalState<ViewRulesetModalParams> }) => modal.params
  );

  const _getRuleset = async () => {
    setIsLoading(true);
    try {
      const ruleset = await api
        .getHuntResult(id, scanType, scanType === 'historical' && hunt_id ? 'true' : undefined)
        .then((res) => res.data);
      setIsLoading(false);
      setRuleset(ruleset.yara);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    _getRuleset();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal fullWidth={true} maxWidth='xl'>
      <ViewRulesetForm
        css={ownStyle}
        value={ruleset}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        onClose={() => dispatch(closeModal())}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
`;

export default ViewRulesetModal;
