import { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { getErrorMessage, RequestError } from 'utils/error';

export const useIntegrations = () => {
  const [integrations, setIntegrations] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  const fetchIntegrations = useCallback(async () => {
    setError('');
    setLoading(true);
    try {
      const response = await api.getIntegrations();

      setIntegrations(response.data);
    } catch (error) {
      const errorMessage = getErrorMessage(error as RequestError);
      setError(errorMessage);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  return {
    integrations: integrations,
    refetch: fetchIntegrations,
    loading: loading,
    error: error,
  };
};
