import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import useNotification from 'hooks/useNotification';
import styles from 'views/styles';
import { ModalState } from 'state/modal';
import Modal from 'views/components/Modal';
import Switch from '@material-ui/core/Switch';
import Select from 'views/components/Select';
import Icon from 'views/components/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import api from 'services/api';
import { toCamelCase } from 'utils/text';
import { ReportInput } from 'services/api/schema/report';

type ModalProps = {
  type: 'scan' | 'sandbox';
  id: string;
};

const sections = {
  scan: ['Summary', 'Detections', 'File Metadata'],
  sandbox: ['Network', 'Extracted Config', 'Dropped Files', 'Analysis'],
};
const MAX_TIMEOUT = 30 * 1000;
const getTime = () => new Date().getTime();

export const useGenerateReport = (closeModal?: () => void) => {
  const notification = useNotification();
  const [isGenerating, setIsGenerating] = useState(false);

  const downloadReport = (url: string) => {
    window.open(url, '_blank');
  };

  const getReport = async (reportId: string, startTime: number, timeout = 1000) => {
    try {
      if (getTime() - startTime >= MAX_TIMEOUT) {
        throw new Error('Report generation timeout');
      }
      const { data } = await api.getReport(reportId);

      if (data.state === 'SUCCEEDED' && data.url) {
        setIsGenerating(false);
        downloadReport(data.url);
        notification.success('Report generated successfully', 5000);
        closeModal && closeModal();
      } else if (data.state === 'FAILED') {
        setIsGenerating(false);
        notification.failure('Report generation failed', 5000);
      } else {
        await new Promise((resolve) => setTimeout(resolve, timeout));
        await getReport(reportId, startTime, timeout * 2);
      }
    } catch (error) {
      setIsGenerating(false);
      notification.failure('Report download failed', 5000);
    }
  };

  const generateReport = async (reportData: ReportInput) => {
    setIsGenerating(true);
    try {
      const { data } = await api.generateReport(reportData);

      if (data.id) {
        notification.info('Generating report...', MAX_TIMEOUT * 2);
        await getReport(data.id, getTime());
      }

      return data;
    } catch (error) {
      setIsGenerating(false);
      notification.failure('Report generation failed', 5000);
    }
  };

  return { generateReport, isGenerating };
};

const GenerateReportModal = () => {
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const { generateReport, isGenerating } = useGenerateReport(_closeModal);
  const dispatch = useDispatch<Dispatch>();
  const [reportFormat, setReportFormat] = useState<'HTML' | 'PDF'>();
  const { type, id } = useSelector(({ modal }: { modal: ModalState<ModalProps> }) => modal.params);
  const [includeSections, setIncludeSections] = useState<string[]>(sections[type]);

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Generate Report</h2>
        <div css={classes.block}>
          <h3 css={classes.subtitle}>Select Report Type</h3>
          <Select
            placeholder='Select report type'
            onChange={(value) => {
              setReportFormat(value);
            }}
            value={reportFormat || ''}
            options={['HTML', 'PDF']}
          />
        </div>
        <div css={classes.block}>
          <div css={classes.row}>
            <h3 css={classes.subtitle}>Include in report</h3>
            <Tooltip
              title="Select the sections you'd like to include in the report"
              placement='top'
            >
              <div>
                <Icon name='info' css={classes.iconInfo} />
              </div>
            </Tooltip>
          </div>
          {sections[type].map((item: string) => (
            <div css={classes.row}>
              <span>{item}</span>
              <Switch
                color='primary'
                onChange={(e) => {
                  if (e.target.checked) {
                    setIncludeSections([...includeSections, item]);
                  } else {
                    setIncludeSections(includeSections.filter((section) => section !== item));
                  }
                }}
                checked={includeSections.includes(item)}
              />
            </div>
          ))}
        </div>
        <div css={classes.row}>
          <Button
            size='medium'
            color='primary'
            variant='outlined'
            onClick={_closeModal}
            disabled={isGenerating}
          >
            Cancel
          </Button>
          <Button
            size='medium'
            type='button'
            color='primary'
            variant='contained'
            disabled={!reportFormat || isGenerating}
            onClick={async () => {
              const baseReport = {
                format: reportFormat!.toLowerCase() as 'html' | 'pdf',
                template_metadata: {
                  includes: includeSections.map(toCamelCase),
                },
              };
              if (type === 'scan') {
                await generateReport({
                  ...baseReport,
                  type,
                  instance_id: id,
                });
              } else if (type === 'sandbox') {
                await generateReport({
                  ...baseReport,
                  type,
                  sandbox_task_id: id,
                });
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '4rem',
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.8rem',
    },
    subtitle: {
      fontSize: '1.6rem',
      fontWeight: 600,
    },
    error: {
      color: styles.color.red,
      fontSize: '1.6rem',
    },
    iconInfo: {
      color: styles.color.xLightGrey,
      cursor: 'help',
      fontSize: '0.6rem',
    },
  },
  light: {},
  dark: {},
});

export default GenerateReportModal;
