import React, { Fragment, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Team, TeamMember } from 'models/Team';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { TeamActionName } from 'state/team/types';
import { getTeamAccount, getTeamByAccount, getAllTeamMembersByAccount } from 'state/team/actions';
import { getUserAccount } from 'state/user/actions';
import { Account } from 'models/Account';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import AppLoader from 'views/components/request/AppLoader';
import TeamDetailLayout from './TeamDetailLayout';
import { getMFAData, removeMFAData } from 'state/auth/actions';
import { openModal } from 'state/modal/actions';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import useTeamMemberAccess, { TeamMemberAccessProvider } from 'hooks/useTeamMemberAccess';

interface StateProps {
  team: Team | null;
  allMembers: TeamMember[];
  account: Account | null;
}

const messages = defineMessages({
  title: {
    id: 'teamsPage.title',
    defaultMessage: 'Teams',
  },
});

export const TeamDetailPage = () => {
  return (
    <TeamMemberAccessProvider>
      <TeamDetailPageContent />
    </TeamMemberAccessProvider>
  );
};

export const TeamDetailPageContent = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const intl = useIntl();
  const { teamMemberAccess, hasAccess, loading } = useTeamMemberAccess();
  const hasTeamMemberAccess = teamMemberAccess || hasAccess;

  const { team, allMembers, account } = useSelector<RootState, StateProps>(({ team }) => ({
    team: team.single,
    allMembers: team.allMembers.results ? team.allMembers.results : [],
    account: team.account,
  }));
  const accountNumber = contextAccount(store)?.accountNumber!;
  const _getTeam = useCallback(
    async (accountNumber: number | string) => {
      if (loading) return;
      if (!!accountNumber) {
        await dispatch(getTeamByAccount(accountNumber));
        await dispatch(getTeamAccount(accountNumber));
        if (hasTeamMemberAccess) {
          await dispatch(getAllTeamMembersByAccount({ includeRoles: true, includeMfa: true }));
        }
      } else {
        // if no teamId is found, assume we are in user context
        history.replace('/account/profile');
      }
    },
    [dispatch, history, loading, hasTeamMemberAccess]
  );

  const _handleUpgrade = () => history.push('/pricing');

  useEffect(() => {
    dispatch(getUserAccount());
    _getTeam(accountNumber);
  }, [_getTeam, dispatch, accountNumber]);

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal } = mfaStep;
      if (['ARCHIVE_TEAM', 'LEAVE_TEAM'].indexOf(modal) >= 0) {
        dispatch(openModal(modal));
        removeMFAData();
      } else if (modal === 'UPDATE_TEAM') {
        dispatch(openModal(modal, { teamId: mfaStep.teamId, values: mfaStep.values }));
        removeMFAData();
      } else if (modal === 'INVITE_TEAM_MEMBER') {
        dispatch(openModal(modal, { teamId: mfaStep.teamId }));
        removeMFAData();
      }
      // Disabling for now because the state is not stored correctly
      // else if (modal === 'ARCHIVE_TEAM_MEMBER') {
      //   dispatch(openModal(modal, {
      //     teamId: mfaStep.teamId,
      //     teamMemberId: mfaStep.teamMemberId,
      //     teamMemberName: mfaStep.teamMemberName,
      //   }));
      //   removeMFAData();
      // }
      // else if (modal === 'UPDATE_TEAM_MEMBER') {
      //   dispatch(openModal(modal, {
      //     teamId: mfaStep.teamId,
      //     teamMemberId: mfaStep.teamMemberId,
      //     name: mfaStep.name,
      //     values: mfaStep.values,
      //   }));
      //   removeMFAData();
      // }
    }
  }, [_getTeam, dispatch]);

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <AppLoader
          loadingActions={[
            TeamActionName.GET_TEAM,
            TeamActionName.GET_ALL_TEAM_MEMBERS,
            TeamActionName.GET_TEAM_ACCOUNT,
          ]}
          onReload={() => _getTeam(accountNumber)}
        >
          {team && account && (
            <TeamDetailLayout
              team={team}
              account={account}
              handleUpgrade={_handleUpgrade}
              allMembers={allMembers}
            />
          )}
        </AppLoader>
      </BaseLayout>
    </Fragment>
  );
};
