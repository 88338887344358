import React, { SFC } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import Card from 'views/components/layout/Card';
import AvatarBackground from './AvatarBackground';
import Breadcrumb from '../Breadcrumb';

interface Props {
  className?: string;
  heading: string;
  text?: string | null;
  avatarUrl: string;
  breadcrumb?: {
    to: string;
    text: string;
  };
  renderContextMenu?: () => JSX.Element;
}

const AvatarSidebar: SFC<Props> = ({
  className,
  heading,
  text,
  avatarUrl,
  breadcrumb,
  renderContextMenu,
  children,
}) => (
  <Card className={className} css={style.card}>
    <AvatarBackground apiUrl={avatarUrl}>
      {breadcrumb && <Breadcrumb css={style.breadcrumb} {...breadcrumb} />}
    </AvatarBackground>
    <div css={style.cardInner}>
      <div css={style.titleWrap}>
        <h4 className='h4' data-cy='avatarSidebarHeading'>
          {heading}
        </h4>
        {typeof renderContextMenu === 'function' && renderContextMenu()}
      </div>
      <p className='h-mt-tiny h-mb-xs p1' data-cy='avatarSidebarText'>
        {text}
      </p>
      {children}
    </div>
  </Card>
);

const style = {
  card: css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 350px;
  `,
  cardInner: css`
    margin-top: 8%;
    flex: 1 0 auto;
    padding: 0 ${styles.spacing.xs} ${styles.spacing.sm};
    h3 {
      font-size: 2.4rem;
    }
  `,
  breadcrumb: css`
    margin: ${styles.spacing.xs};
    padding: 0.8rem 2rem;
    border-radius: 24px;
    color: ${styles.color.black};
    background-color: ${styles.color.white};
    z-index: 1;
  `,
  titleWrap: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
};

export default AvatarSidebar;
