import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { PageQuery } from 'models/Page';
import { MicroengineListing } from 'models/Microengine';
import { openModal, closeModal } from 'state/modal/actions';
import { Dispatch } from 'state/types/thunk';
import { RootState } from 'state/root';
import { WebhookDataRes } from 'services/api/schema/webhooks';
import { WebhooksActionName } from 'state/webhooks/types';
import { extractPageQueryParams, DEFAULT_LIMIT } from 'utils/pagination';
import { getUserMicroengines as getUserMicroenginesAction } from 'state/microengines/actions';
import { getWebhooks, createWebhook, archiveWebhook, testWebhook } from 'state/webhooks/actions';
import PanelContent from 'views/components/layout/PanelContent';
import AppLoader from 'views/components/request/AppLoader';
import WebhooksTable from './WebhooksTable';
import { CreateWebhookDialog } from './CreateWebhookDialog';
import useInterval from './hooks';
import { ESortDirection, IColumnSort } from '../InfiniteScrollTable';
import { useGetWebhook } from './hooks/useGetWebhook';

interface IWebhooksTableContainer {
  teamId?: number;
}

interface NewWebhook {
  url: string;
  requestsPerDay: number;
}

const messages = defineMessages({
  webhook: {
    id: 'webhooksTab.webhook',
    defaultMessage: 'webhook',
  },
  webhookHeading: {
    id: 'webhooksTab.webhookHeading',
    defaultMessage: 'Create your webhooks',
  },
  webhookText: {
    id: 'webhooksTab.webhookText',
    defaultMessage: 'Create your first webhook by clicking below.',
  },
  createWebhook: {
    id: 'webhooksTab.createWebhook',
    defaultMessage: 'Create webhook',
  },
});

const WebhooksTableContainer = ({ teamId }: IWebhooksTableContainer) => {
  const [sort, setSort] = useState<IColumnSort<keyof WebhookDataRes>>({
    orderBy: 'createdAt',
    direction: ESortDirection.ASC,
  });
  const [testingWebhooks, setTestingWebhooks] = useState<string[]>([]);
  const { getWebhook } = useGetWebhook();

  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();
  const { webhooks } = useSelector((state: RootState) => state);
  const microengines = useSelector((state: RootState) => state.microengines.user);
  const associatedWebhooks =
    microengines?.results?.length > 0
      ? (microengines.results
          .map(({ webhookId }) => webhookId)
          .filter((webhook) => typeof webhook === 'string') as string[])
      : ([] as string[]);

  const _getWebhooks = (refresh: boolean, query?: PageQuery<WebhookDataRes>) => {
    const teamQuery = teamId ? { teamIds: [teamId] } : { filterPersonal: true };
    const params = Object.assign({}, extractPageQueryParams(webhooks), query, teamQuery);
    dispatch(getWebhooks(params, refresh));
  };

  const _getUserMicroengines = (refresh: boolean, query?: PageQuery<MicroengineListing>) => {
    const params = Object.assign({}, extractPageQueryParams(microengines), query);
    dispatch(getUserMicroenginesAction(params, refresh));
  };

  const _createWebhook = async (values: NewWebhook) => {
    await dispatch(createWebhook(values));
    _getWebhooks(true, { offset: 0 });
  };

  const _testWebhook = async (webhookId: string) => {
    await dispatch(testWebhook(webhookId));
    setTestingWebhooks([...testingWebhooks, webhookId]);
    _getWebhooks(true, { offset: 0 });
  };

  const _archiveWebhook = async (webhookId: string) => {
    await dispatch(archiveWebhook(webhookId));
    _getWebhooks(true, { offset: 0 });
  };

  const _handleSort = ({ orderBy, direction }: any) => {
    setSort({ orderBy, direction });
    _getWebhooks(true, {
      offset: 0,
      orderBy,
      direction,
    });
  };

  useInterval(() => {
    if (testingWebhooks.length > 0) {
      testingWebhooks.forEach(async (webhookId) => {
        const { webhook } = await getWebhook(webhookId);
        if (webhook && webhook.status !== 'pending') {
          const filteredTestingWebhooks = testingWebhooks.filter(
            (webhook) => webhook !== webhookId
          );
          setTestingWebhooks(filteredTestingWebhooks);
          if (webhook.status === 'failed') {
            dispatch(
              openModal('ERROR_MODAL', {
                heading: 'Webhook Test Failed',
                icon: 'malicious',
                text: webhook.lastAttempt?.response || 'Unknown error',
                buttonText: 'OK',
                buttonClass: 'secondary',
                onClick: () => dispatch(closeModal()),
              })
            );
          }
          _getWebhooks(true);
        }
      });
    }
  }, 10000);

  // Only need to fetch data on mount
  useEffect(() => {
    _getWebhooks(true, { offset: 0 });
    _getUserMicroengines(true, { offset: 0 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadMore = () => {
    const offset: number =
      typeof webhooks.offset === 'number' ? webhooks.offset + DEFAULT_LIMIT : 0;
    _getWebhooks(false, { offset });
    return Promise.resolve();
  };

  return (
    <AppLoader
      loadingActions={[WebhooksActionName.REFRESH_WEBHOOKS]}
      onReload={() => _getWebhooks(true)}>
      {() => {
        if (!webhooks.results) {
          return null;
        }
        return Array.isArray(webhooks.results) && webhooks.results.length > 0 ? (
          <WebhooksTable
            webhooks={webhooks}
            testingWebhooks={testingWebhooks}
            associatedWebhooks={associatedWebhooks}
            archiveWebhook={_archiveWebhook}
            createWebhook={_createWebhook}
            testWebhook={_testWebhook}
            sort={sort}
            onSort={_handleSort}
            onLoadMore={onLoadMore}
          />
        ) : (
          <PanelContent
            // image={{ src: webhookImg, alt: intl.formatMessage(messages.webhook) }}
            heading={intl.formatMessage(messages.webhookHeading)}
            text={intl.formatMessage(messages.webhookText)}
            customButtons={[
              <CreateWebhookDialog createWebhook={_createWebhook}>
                {(open: () => void) => (
                  <Button component='button' color='primary' variant='contained' onClick={open}>
                    {intl.formatMessage(messages.createWebhook)}
                  </Button>
                )}
              </CreateWebhookDialog>,
            ]}
          />
        );
      }}
    </AppLoader>
  );
};

export default WebhooksTableContainer;
