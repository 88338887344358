import { useEffect, useState } from 'react';
import { TeamActivityRow } from '../types';
import api from 'services/api';
import { getErrorMessage } from 'utils/error';
import { RequestError } from 'utils/error';
// import useHasFeature from 'hooks/useHasFeature';

function useTeamActivity(defaultParams: Record<string, any> = {}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | string>(false);
  const [data, setData] = useState<TeamActivityRow[]>([]);
  const [more, setMore] = useState<boolean>(false);
  const [nextOffset, setNextOffset] = useState<string | null>(null);

  const fetchTeamActivity = async (params: Record<string, any> = {}) => {
    setLoading(true);
    setError(false);

    try {
      const activities = await api.getTeamActivity({
        '-event_type': 'scan_create',
        ...params,
        ...(nextOffset ? { offset: nextOffset } : {}),
      });

      if (activities.status !== 200) {
        setError('Error fetching team activity');
        return;
      }

      setMore(activities.data.hasMore);
      setNextOffset(activities.data.offset);
      setData(
        activities.data.result.map((activity) => {
          return {
            timestamp: activity.event_timestamp,
            eventType: activity.event_type,
            user:
              activity?.user?.name && activity?.user?.email
                ? `${activity.user.name} <${activity.user.email}>`
                : '-',
            metadata: JSON.stringify(activity),
          };
        })
      );
    } catch (error) {
      setError(getErrorMessage(error as RequestError));
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTeamActivity(defaultParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    data,
    more,
    refetch: fetchTeamActivity,
    reset: () => {
      setData([]);
      setMore(false);
      setNextOffset(null);
    },
  };
}
export type TeamActivityProps = ReturnType<typeof useTeamActivity>;

export default useTeamActivity;
