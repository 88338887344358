import React, { FC } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { USER_AVATAR_URL, TEAM_AVATAR_URL, COMMUNITY_AVATAR_URL } from 'utils/constants';

interface AvatarProps {
  className?: string;
}

interface UserAvatarProps extends AvatarProps {
  username: string;
}

interface TeamAvatarProps extends AvatarProps {
  teamName: string;
}

interface CommunityAvatarProps extends AvatarProps {
  communityName: string;
}

const Avatar: FC<AvatarProps> = ({ className, children }) => (
  <div className={className} css={style.avatar}>
    {children}
  </div>
);

export const UserAvatar = ({ username, ...rest }: UserAvatarProps) => (
  <Avatar css={style.userAvatar} {...rest}>
    <img src={`${USER_AVATAR_URL}/${username}`} alt={`${username} avatar`} />
  </Avatar>
);

export const TeamAvatar = ({ teamName, ...rest }: TeamAvatarProps) => (
  <Avatar {...rest}>
    <img src={encodeURI(`${TEAM_AVATAR_URL}/${teamName}`)} alt={teamName} />
  </Avatar>
);

export const CommunityAvatar = ({ communityName, ...rest }: CommunityAvatarProps) => (
  <Avatar {...rest}>
    <img src={encodeURI(`${COMMUNITY_AVATAR_URL}/${communityName}`)} alt={communityName} />
  </Avatar>
);

const style = {
  avatar: css`
    font-size: 1rem;
    width: 14em;
    height: 14em;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      display: block;
      position: relative;
      left: 0.3em;
    }
  `,
  userAvatar: css`
    border-radius: 100%;
    /* no var becuase this is just to fix tinygraphs bg */
    background-color: #624ae9;
  `,
};
