import { PlansActionName, SwitchIntervalAction, SelectPlanAction } from './types';

export const selectPlan = (plan: string | null): SelectPlanAction => ({
  type: PlansActionName.SELECT_PLAN,
  plan,
});

export const switchInterval = (interval: string): SwitchIntervalAction => ({
  type: PlansActionName.SWITCH_INTERVAL,
  interval,
});
