import React, { useState, useEffect } from 'react';
import api from 'services/api';
import { useUser } from 'views/components/providers/UserProvider';
import { getErrorMessage, RequestError } from 'utils/error';
import { getAccountStoredKey } from 'utils/storage/storage';
import { HashResult, SearchEnum, IocEnum, SearchType, IOCResult } from './types';
import mitreJSONData from 'views/pages/SandboxPage/SandboxTab/mitreData';

type Search = {
  seachType: SearchType;
  iocType: IocEnum;
  text: string;
};

const iocTypes: Record<IocEnum, string> = {
  IP: 'ip',
  URL: 'domain',
  'MITRE TTP': 'ttp',
  Imphash: 'imphash',
};

const startDateKey = 'iocSearchingStartDate';
const endDateKey = 'iocSearchingEndDate';
export const useIOCSearch = () => {
  const [results, setResults] = useState<HashResult | IOCResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastSearch, setLastSearch] = useState<Search | null>(null);
  const [error, setError] = useState('');
  const { accountNumber } = useUser();
  const [startDate, setStartDate] = useState({
    option: 'DAYS',
    value: 30,
  });
  const [endDate, setEndDate] = useState({
    option: 'TODAY',
    value: 0,
  });

  useEffect(() => {
    if (!!accountNumber) {
      const storagedStartDate = getAccountStoredKey(accountNumber, startDateKey);
      const storagedEndDate = getAccountStoredKey(accountNumber, endDateKey);

      setStartDate(storagedStartDate || startDate);
      setEndDate(storagedEndDate || endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber]);

  async function fetchIOCSearch(params: Search) {
    const { seachType, iocType, text } = params;
    setError('');
    setLoading(true);
    setLastSearch(params);
    try {
      if (seachType === SearchEnum.Hash) {
        const response = await api.getIOCSearchByHash(text);
        const { data } = response;

        setResults(
          data.result
            ? [
                {
                  component: 'list',
                  data: {
                    imphash: data.result.imphash,
                    ips: data.result.ips,
                    urls: data.result.urls,
                  },
                },
                {
                  component: 'table',
                  title: 'MITRE TTPs',
                  data: data.result.ttps.map((item) => ({
                    ID: (
                      <a
                        className='p a'
                        href={`https://attack.mitre.org/techniques/${item.replace('.', '/')}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {item}
                      </a>
                    ),
                    name: mitreJSONData[item as keyof typeof mitreJSONData] || '',
                  })),
                },
              ]
            : {}
        );
      } else {
        const response = await api.getIOCSeach({
          [iocTypes[iocType]]: text,
          startDate: startDate.option === 'TODAY' ? '1d' : `${startDate.value}d`,
          endDate: endDate.option === 'TODAY' ? '1d' : `${endDate.value}d`,
        });
        const { data } = response;
        const results = data.result
          ? data.result.map((hash) => ({
              hash,
            }))
          : [];
        setResults(results);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error as RequestError);
      setError(errorMessage);
    }
    setLoading(false);
  }

  return {
    results: results,
    fetchIOCSearch,
    cleanResults: () => setResults(null),
    retry: () => (lastSearch ? fetchIOCSearch(lastSearch) : null),
    loading: loading,
    error: error,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateKey,
    endDateKey,
  };
};
