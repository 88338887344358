import React, { Fragment } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import styles from 'views/styles';

interface Props<T> {
  className?: string;
  dataCy?: string;
  rows: T[];
  renderTableHead: () => JSX.Element;
  renderTableRow: (data: T) => JSX.Element | null;
  renderFab?: () => JSX.Element | null;
  renderPagination?: React.ReactNode;
}

type NestedKeys<T> = {
  [K in keyof T]: K extends string | number
    ? T[K] extends object
      ? `${K}.${NestedKeys<T[K]>}`
      : K
    : never;
}[keyof T];

export type Row<T> = {
  id: Extract<NestedKeys<T>, string>;
  numeric: boolean;
  label: string;
  sortable: boolean;
  width?: number;
};

const CustomTable = <T extends {}>({
  dataCy,
  rows,
  renderTableHead,
  renderTableRow,
  renderFab,
  renderPagination,
}: Props<T>) => (
  <Fragment>
    <Table data-cy={dataCy} css={style.table}>
      {renderTableHead()}
      <TableBody>{rows.map(renderTableRow)}</TableBody>
    </Table>
    {renderPagination && (
      <div css={style.footer}>
        <div>{renderPagination}</div>
      </div>
    )}
    <div css={style.fab}>{renderFab && renderFab()}</div>
  </Fragment>
);

const style = {
  table: css`
    tr {
      td,
      th {
        padding: 0.8rem;
      }
      th:first-of-type,
      td:first-of-type {
        padding-left: ${styles.spacing.grid};
        transform: translateZ(0);
      }
    }
  `,
  fab: css`
    display: flex;
    justify-content: flex-end;
  `,
  footer: css`
    display: flex;
    justify-content: space-between;
    padding: ${styles.spacing.grid} 24px 0 ${styles.spacing.grid};
  `,
};

export default CustomTable;
