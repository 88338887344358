import React, { Fragment, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useIntl, defineMessages } from 'react-intl';
import SEO from 'views/components/layout/SEO';
import BaseLayout from 'views/components/layout/BaseLayout';
import { PricingPanel, ETabName } from './PricingPanel';
import { PricingSidebar } from './PricingSidebar/PricingSidebar';
import { ScrollToTopOnMount } from 'views/components/ScrollToTop';

const messages = defineMessages({
  title: {
    id: 'pricingPage.title',
    defaultMessage: 'Pricing',
  },
});

const PricingPage = () => {
  const [tab, setTab] = useState(ETabName.INDIVIDUAL);
  const intl = useIntl();

  return (
    <Fragment>
      <SEO title={intl.formatMessage(messages.title)} />
      <BaseLayout>
        <ScrollToTopOnMount />
        <div className='container h-mt-sm h-mb-lg'>
          <div className='row'>
            <div className='col-8 col-lg-9'>
              <PricingPanel onChangeTab={setTab} />
            </div>
            <div className='col-4 col-lg-3'>
              <PricingSidebar tab={tab} />
            </div>
          </div>
        </div>
      </BaseLayout>
    </Fragment>
  );
};

export default PricingPage;
