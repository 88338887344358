import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

interface Props<T> {
  className?: string;
  rows: T[];
  showActions?: boolean;
  actionAlign?: 'right' | 'left' | 'center';
  renderHeaderCell: (data: T) => JSX.Element;
}

const CustomTableHead = <T extends {}>({
  className,
  rows,
  showActions,
  actionAlign = 'right',
  renderHeaderCell,
}: Props<T>) => (
  <TableHead className={className}>
    <TableRow>
      {rows.map(renderHeaderCell)}
      {showActions && (
        <TableCell align={actionAlign}>
          <FormattedMessage id='table.head.actions' defaultMessage='Actions' />
        </TableCell>
      )}
    </TableRow>
  </TableHead>
);

export default CustomTableHead;
