import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useIntl } from 'react-intl';
import { ETeamRole } from 'models/Team';
import { messages as teamMessages } from 'state/team/messages';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { UserAvatar } from '../Avatar';

interface IUserBadge {
  className?: string;
  username: string;
  role?: ETeamRole;
}

const UserBadge = ({ className, username, role }: IUserBadge) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <div className={className} css={classes.root}>
      <UserAvatar css={classes.avatar} username={username} />
      <div css={classes.content}>
        <p className='p1' css={classes.username}>
          {username}
        </p>
        {role && (
          <div className='p1' css={classes.role}>
            {intl.formatMessage(teamMessages[role])}
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      display: 'flex',
    },
    avatar: {
      fontSize: '0.28rem !important',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: '1.8rem',
      marginLeft: '1.2rem',
    },
    username: {
      fontWeight: styles.font.weight.medium,
    },
    role: {
      color: styles.color.xLightGrey,
    },
  },
  light: {
    username: {
      color: styles.color.black,
    },
  },
  dark: {
    username: {
      color: styles.color.white,
    },
  },
});

export default UserBadge;
