import React, { useEffect } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Switch from '@material-ui/core/Switch';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styles from 'views/styles';
import { RootState } from 'state/root';
import { openModal } from 'state/modal/actions';
import {
  hasMadeScopedMFARecently,
  loginWriteTeams,
  getMFAData,
  removeMFAData,
} from 'state/auth/actions';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { errorMessages } from 'utils/error';
import useForceTeamMfa from './useForceTeamMfa';
import { Button, Divider } from '@material-ui/core';
import Icon from 'views/components/Icon';
import { ThemePreview, Variant } from 'views/components/ThemePreview';
import usePrivateCommunityTheme from 'hooks/usePrivateCommunityTheme';
import useHasPrivateCommunity from 'hooks/useHasPrivateCommunity';
import useTeamMemberAccess from 'hooks/useTeamMemberAccess';
import { ReportTemplateSection } from './ReportTemplateSection';
import useIsCommunityPlan from 'hooks/useIsCommunityPlan';
import { switchAndRefreshAccount } from 'state/account/actions';

export const TeamAdvancedTab = () => {
  const user = useUser();
  const intl = useIntl();
  const location = useLocation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { forceTeamMfa } = useForceTeamMfa();
  const { teamMemberAccess, toggleAccess, loading } = useTeamMemberAccess();
  const { single: team } = useSelector(({ team }: RootState) => team);
  const { variant: privateComminityTheme, setVariant } = usePrivateCommunityTheme();
  const hasPrivateCommunity = useHasPrivateCommunity();
  const isCommunityPlan = useIsCommunityPlan();

  const onSetup2FA = async () => {
    await dispatch(switchAndRefreshAccount(user.accountNumber, false, '/account/advanced'));
  };

  const _faCheck = (data: { location: string; forceMfa: boolean }) => {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              ...data,
              modal: 'FORCE_MFA',
            })
          );
        },
      })
    );
  };

  const _membersCheck = (data: { location: string; canViewTeamMembers: boolean }) => {
    dispatch(
      openModal('FA_CHECK', {
        onSubmit: () => {
          dispatch(
            loginWriteTeams({
              ...data,
              modal: 'TEAM_MEMBER_ACCESS',
            })
          );
        },
      })
    );
  };

  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, forceMfa, canViewTeamMembers } = mfaStep;
      if (modal === 'FORCE_MFA') {
        forceTeamMfa(forceMfa);
        removeMFAData();
      } else if (modal === 'TEAM_MEMBER_ACCESS') {
        setTimeout(() => {
          toggleAccess(canViewTeamMembers);
          removeMFAData();
        }, 500);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null;
  return user.mfaEnabled ? (
    <div>
      <div css={classes.container}>
        <div>
          <h3 css={classes.title}>Secure Authentication</h3>
          <div css={classes.switchContainer}>
            <p>Require 2FA for all Team Members</p>
            <Switch
              color='primary'
              checked={team?.forceMfa}
              onChange={(e) => {
                if (!hasMadeScopedMFARecently()) {
                  _faCheck({
                    location: location.pathname,
                    forceMfa: e.target.checked,
                  });
                } else {
                  forceTeamMfa(e.target.checked);
                }
              }}
            />
          </div>
        </div>
        <div>
          <h3 css={classes.title}>Restrict access to Members tab</h3>
          <div css={classes.switchContainer}>
            <p>Members tab only accessibly by Team Owner and Team Admin</p>
            <Switch
              color='primary'
              checked={!teamMemberAccess}
              onChange={(e) => {
                if (!hasMadeScopedMFARecently()) {
                  _membersCheck({
                    location: location.pathname,
                    canViewTeamMembers: !teamMemberAccess,
                  });
                } else {
                  toggleAccess(!teamMemberAccess);
                }
              }}
            />
          </div>
        </div>
        {!isCommunityPlan && (
          <>
            <Divider css={classes.divider} />
            <ReportTemplateSection />
          </>
        )}
        {hasPrivateCommunity && (
          <>
            <Divider css={classes.divider} />
            <div css={classes.section}>
              <h3 css={classes.title}>Private Mode Theme</h3>
              <div css={classes.themePreviewContainer}>
                {(['salmon', 'forest', 'mustard'] as const).map((variant) => (
                  <div
                    data-selected={privateComminityTheme === variant}
                    css={classes.themePreview}
                    onClick={() => {
                      setVariant(variant);
                    }}
                  >
                    <div css={classes.containerTitle}>
                      <div css={classes.titleTheme}>
                        <h3 css={classes.title}>{variant}</h3>
                        <Icon css={classes.iconTheme} name='sun' />
                      </div>
                      <Icon
                        data-selected={privateComminityTheme === variant}
                        css={classes.check}
                        name='check-alt'
                      />
                    </div>
                    <ThemePreview variant={variant} />
                    <div css={classes.titleTheme}>
                      <h3 css={classes.title}>{variant} - Dark</h3>
                      <Icon css={classes.iconTheme} name='moon' />
                    </div>
                    <ThemePreview variant={`${variant}-dark` as Variant} />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div css={classes.noAccessContainer}>
      <p css={classes.title}>{intl.formatMessage(errorMessages.teamAdvanced)}</p>
      <Button size='small' color='primary' variant='contained' onClick={onSetup2FA}>
        Go there
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      padding: '1rem 4rem',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    divider: {
      margin: '2.5rem 0 4.5rem 0 !important',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    bold: {
      fontSize: '1.4rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    label: {
      fontSize: '0.9rem',
      padding: '0.6rem 0.8rem',
      borderRadius: '2rem',
      background: styles.color.xxLightGrey,
      color: styles.color.black,
      textTransform: 'uppercase',
    },
    templateContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    templateSection: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      alignItems: 'center',
    },
    uploadIcon: {
      fontSize: '0.8rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginLeft: styles.spacing.tiny,
    },
    infoIcon: {
      fontSize: '0.5rem',
      color: styles.color.lightGrey,
    },
    uploadBtn: {
      background: styles.color.purple,
      color: styles.color.white,
      padding: '0.8rem 1.2rem',
      borderRadius: '1rem',
      cursor: 'pointer',
      display: 'flex',
      width: 'max-content',
      fontSize: '1.3rem',
      alignItems: 'center',
      gap: '1.5rem',
      '&:disabled': {
        opacity: '0.6',
        transition: 'opacity 300ms ease-in-out',
        cursor: 'not-allowed',
      },
    },
    dangerLink: {
      color: styles.color.red,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500,
    },
    primaryLink: {
      color: styles.color.purple,
      cursor: 'pointer',
      fontWeight: 500,
    },
    textarea: {
      width: '100%',
      padding: '2rem 1rem',
      borderRadius: '1rem',
      border: `2px solid ${styles.color.xxLightGrey}`,
      color: styles.color.black,
      background: styles.color.xxxLightPurple,
      minHeight: '10rem',
      maxHeight: '10rem',
      resize: 'none',
      overflowY: 'auto',
    },
    textareaContainer: {
      position: 'relative',
    },
    textareaChars: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      color: styles.color.xLightGrey,
    },
    switchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem 0',
      width: 'max-content',
      gap: '1rem',
    },
    noAccessContainer: {
      padding: ' 2rem 4rem',
      display: 'flex',
      gap: '2rem',
      alignItems: 'center',
    },
    themePreviewContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '2rem',
      overflowX: 'scroll',
    },
    themePreview: {
      display: 'flex',
      cursor: 'pointer',
      width: 'auto',
      flexDirection: 'column',
      gap: '2rem',
      padding: '2.5rem',
      borderRadius: '1rem',
      transition: `border 300ms ease-in-out`,
      "&[data-selected='true']": {
        border: `3px solid ${styles.color.medGreen}`,
      },
    },
    check: {
      color: 'transparent',
      fontSize: '0.5rem',
      transition: `color 300ms ease-in-out, transform 100ms ease-in-out`,
      transform: 'scale(0.5)',
      transitionDelay: '100ms',
      "&[data-selected='true']": {
        color: styles.color.medGreen,
        transform: 'scale(1)',
      },
    },
    containerTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleTheme: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.8rem',
    },
    iconTheme: {
      fontSize: '0.6rem',
      marginTop: '-0.3rem',
    },
  },
  light: {
    title: {
      color: styles.color.black,
    },
    themePreview: {
      background: styles.color.xxLightGrey,
      border: `3px solid ${styles.color.xxLightGrey}`,
    },
    titleTheme: {
      color: styles.color.purple,
    },
  },
  dark: {
    title: {
      color: styles.color.white,
    },
    themePreview: {
      background: styles.color.xDarkPurple,
      border: `3px solid ${styles.color.xDarkPurple}`,
    },
    titleTheme: {
      color: styles.color.lightBlue,
    },
  },
});
