import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import Loader from 'views/components/Loader';
import useNotification from 'hooks/useNotification';
import api from 'services/api';
import { TemplateRes } from 'services/api/schema/report';
import { getErrorMessage } from 'utils/error';
import { useGenerateReport } from 'views/pages/ScanPage/ScanResultsPage/modals/GenerateReportModal/GenerateReportModal';

export const TemplateSection = ({
  title,
  children,
  info,
  types,
}: {
  title: string;
  children: React.ReactNode;
  info?: string;
  types: Array<'pdf' | 'html'>;
}) => {
  const { classes } = useStyles();
  return (
    <div css={classes.templateContainer}>
      <div css={classes.templateSection}>
        <h4 css={classes.bold}>{title}</h4>
        {types.map((type) => (
          <span css={classes.label}>{type}</span>
        ))}
        <Tooltip
          title={
            info ||
            (types.length > 1
              ? 'This applies to both PDF and HTML reports'
              : `This applies to ${types[0].toUpperCase()} reports`)
          }
          placement='top'
        >
          <div css={classes.info} tabIndex={0}>
            <Icon name='info' css={classes.infoIcon} />
          </div>
        </Tooltip>
      </div>
      {children}
    </div>
  );
};

const Textarea = ({
  defaultValue,
  onValueChange,
  placeholder,
  maxLength,
  onConfirm,
}: {
  placeholder: string;
  defaultValue: string;
  maxLength?: number;
  onValueChange?: (text: string) => void;
  onConfirm: (text: string) => Promise<void>;
}) => {
  const { classes } = useStyles();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div css={classes.textareaContainer}>
      <textarea
        css={classes.textarea}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const text = e.target.value;
          if (maxLength && text.length > maxLength) return;
          setValue(text);
          onValueChange?.(text);
        }}
        onBlur={() => {
          if (value !== defaultValue) {
            onConfirm(value);
          }
        }}
      />
      {maxLength && <div css={classes.textareaChars}>{`${value.length} / ${maxLength}`}</div>}
    </div>
  );
};

const ColorInput = ({
  value,
  onChange,
  onConfirm,
}: {
  value: string;
  onChange?: (color: string) => void;
  onConfirm: (color: string) => Promise<void>;
}) => {
  const { classes } = useStyles();
  const [color, setColor] = useState(value);

  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div css={classes.colorContainer}>
        <input
          type='text'
          placeholder='Primary color'
          maxLength={7}
          css={classes.colorInputText}
          value={color}
          onChange={(e) => {
            if (e.target.value.length === 1) {
              if (e.target.value !== '#' && e.target.value.match(/[0-9A-Fa-f]/)) {
                e.target.value = `#${e.target.value}`;
              } else if (e.target.value !== '#') {
                return;
              }
            } else if (e.target.value.length > 1) {
              if (!e.target.value.startsWith('#') && e.target.value.match(/[0-9A-Fa-f]/)) {
                e.target.value = `#${e.target.value}`;
              }
              const lastChar = e.target.value[e.target.value.length - 1];
              if (e.target.value.length > 7 || !lastChar.match(/[0-9A-Fa-f]/)) return;
            }
            const newColor = e.target.value;
            setColor(newColor);
            onChange?.(newColor);
          }}
        />
        <input
          type='color'
          value={color}
          placeholder='Primary color'
          css={classes.inputColor}
          onChange={(e) => {
            const newColor = e.target.value;
            setColor(newColor);
            onChange?.(newColor);
          }}
          onBlur={() => {
            if (color !== value) {
              onConfirm(color);
            }
          }}
        />
      </div>
    </div>
  );
};

const UploadButton = ({
  data,
  onFileLoad,
  text,
  loadingText,
  onFileDelete,
}: {
  data?: string;
  text: string;
  loadingText: string;
  onFileLoad: (data: { base64: string; file: File }) => Promise<void>;
  onFileDelete: () => void;
}) => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  if (data) {
    return (
      <div css={classes.row}>
        <img src={data} alt='logo' style={{ maxHeight: '4rem' }} />
        <Icon name='close' css={classes.closeIcon} onClick={onFileDelete} />
      </div>
    );
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files.length) return;
    const file = event.target.files[0];
    const reader = new FileReader();
    setLoading(true);

    reader.onloadend = async () => {
      if (reader.result && typeof reader.result === 'string') {
        const base64 = reader.result.split(',')[1];
        try {
          await onFileLoad({
            base64,
            file,
          });
        } catch (error) {
          console.error(error);
        } finally {
          if (inputFileRef.current) {
            inputFileRef.current.value = '';
          }
          setLoading(false);
        }
      }
    };

    reader.onerror = () => {
      setLoading(false);
    };

    reader.readAsDataURL(file);
  };

  return (
    <button
      disabled={loading}
      css={classes.uploadBtn}
      onClick={() => inputFileRef.current?.click()}
    >
      <Icon name='upload' css={classes.uploadIcon} />
      <input
        type='file'
        accept='image/png, image/jpeg'
        style={{ display: 'none' }}
        ref={inputFileRef}
        onChange={handleFileChange}
      />
      {loading ? loadingText : text}
    </button>
  );
};

const ConfirmImportantAction = ({
  text,
  onConfirm,
}: {
  text: string;
  onConfirm: () => Promise<void>;
}) => {
  const { classes } = useStyles();
  const [confirming, setConfirming] = useState(false);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loader css={classes.loader} />;
  }

  return (
    <div>
      {confirming ? (
        <div>
          <div css={classes.row}>
            <span onClick={() => setConfirming(false)} style={{ cursor: 'pointer' }}>
              Cancel
            </span>
            <span
              onClick={async () => {
                setLoading(true);
                await onConfirm();
                setConfirming(false);
                setLoading(false);
              }}
              css={classes.dangerLink}
            >
              Confirm
            </span>
          </div>
        </div>
      ) : (
        <div
          css={classes.dangerLink}
          onClick={() => {
            setConfirming(true);
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

const labels = {
  logo: 'Logo',
  primary_color: 'Primary color',
  footer_text: 'Footer text',
  last_page_text: 'Disclaimer text',
} as const;
const defaultTemplate = {
  template_name: 'default',
  primary_color: styles.color.purple.replace('#', ''), // main tenant color
  footer_text: '',
  last_page_text: '',
  is_default: true,
};
type Label = keyof typeof labels;

const useReportTemplate = () => {
  const [template, setTemplate] = useState<TemplateRes>();
  const notification = useNotification();

  useEffect(() => {
    getTemplate().then((defaultTemplate) => setTemplate(defaultTemplate));
  }, []);

  const getTemplate = async () => {
    const defaultTemp = await api.getDefaultTemplate();
    if (defaultTemp.data.result.length > 0) {
      const template = defaultTemp.data.result[0];
      if (template.logo_url) {
        const logo = await api.getTempalteLogo(template.logo_url.replace('/api', ''));
        template.logo_base64 = logo;
      }
      return template;
    } else {
      const newTemplate = await api.createTemplate(defaultTemplate);
      return newTemplate.data;
    }
  };

  const resetTemplate = async () => {
    try {
      await api.deleteTemplate(template!.id);
      const resetTemplate = await getTemplate();
      setTemplate(resetTemplate);
      notification.success('Template reset to default');
    } catch (error) {
      notification.failure('Failed to reset template');
    }
  };

  const uploadLogo = async (file: File) => {
    try {
      const response = await api.uploadTemplateLogo(file, template!.id);

      const { data: updatedTemplate } = response;
      const logo = await api.getTempalteLogo(updatedTemplate.logo_url!.replace('/api', ''));
      updatedTemplate.logo_base64 = logo;
      setTemplate(updatedTemplate);
      notification.success('Logo uploaded successfully');
    } catch (error) {
      const err = getErrorMessage(error as Error);
      notification.failure(`Failed to upload logo: ${err}`);
    }
  };

  const deleteLogo = async () => {
    try {
      await api.deleteTemplateLogo(template!.id);
      setTemplate({
        ...template!,
        logo_base64: undefined,
        logo_url: undefined,
      });
      notification.success('Logo deleted successfully');
    } catch (error) {
      notification.failure('Failed to delete logo');
    }
  };

  const updateTemplateField = async (field: keyof TemplateRes, value: string) => {
    const updatedTemplate = await api.editTemplate(template!.id, {
      [field as keyof TemplateRes]: value,
    });
    setTemplate({
      ...template!,
      ...updatedTemplate.data,
    });
    notification.success(`${labels[field as Label]} updated successfully`);
  };

  return { template, uploadLogo, deleteLogo, resetTemplate, updateTemplateField };
};

export const ReportTemplateSection = () => {
  const { classes } = useStyles();
  const { template, resetTemplate, uploadLogo, deleteLogo, updateTemplateField } =
    useReportTemplate();
  const { generateReport } = useGenerateReport();

  return (
    <div css={classes.section}>
      <h3 css={classes.title}>Configure Report Template</h3>
      <TemplateSection
        title={labels.logo}
        types={['pdf']}
        info='Supported formats: PNG and JPEG. Max file size: 40KB. Max dimensions: 960x960px.'
      >
        <UploadButton
          data={template?.logo_base64}
          text='Upload logo'
          loadingText='Uploading logo...'
          onFileLoad={async (data) => {
            await uploadLogo(data.file);
          }}
          onFileDelete={async () => {
            await deleteLogo();
          }}
        />
      </TemplateSection>
      <TemplateSection title={labels.primary_color} types={['pdf', 'html']}>
        <ColorInput
          value={template?.primary_color ? `#${template.primary_color}` : ''}
          onConfirm={(color) => updateTemplateField('primary_color', color.replace('#', ''))}
        />
      </TemplateSection>
      <TemplateSection
        title={labels.footer_text}
        types={['pdf']}
        info='This will appear at the bottom of each page in the PDF report.'
      >
        <Textarea
          defaultValue={template?.footer_text || ''}
          onConfirm={(text) => updateTemplateField('footer_text', text)}
          placeholder={labels.footer_text}
          maxLength={100}
        />
      </TemplateSection>
      <TemplateSection
        title='Disclaimer text'
        types={['pdf']}
        info='This will appear on the last page of the PDF report.'
      >
        <Textarea
          defaultValue={template?.last_page_text || ''}
          onConfirm={(text) => updateTemplateField('last_page_text', text)}
          placeholder={labels.last_page_text}
          maxLength={1024}
        />
      </TemplateSection>
      <div css={classes.templateSection}>
        <ConfirmImportantAction
          text='Reset to default'
          onConfirm={async () => {
            await resetTemplate();
          }}
        />
        <div
          css={classes.primaryLink}
          onClick={async () => {
            if (process.env.REACT_APP_SCAN_REPORT_ID) {
              await generateReport({
                format: 'pdf',
                type: 'scan',
                instance_id: process.env.REACT_APP_SCAN_REPORT_ID,
              });
            } else {
              console.error('Env variable REACT_APP_SCAN_REPORT_ID not set');
            }
          }}
        >
          Preview Report
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      alignItems: 'center',
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 700,
      textTransform: 'capitalize',
    },
    bold: {
      fontSize: '1.4rem',
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    label: {
      fontSize: '0.9rem',
      padding: '0.6rem 0.8rem',
      borderRadius: '2rem',
      background: styles.color.xxLightGrey,
      color: styles.color.black,
      textTransform: 'uppercase',
    },
    templateContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    templateSection: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      alignItems: 'center',
    },
    uploadIcon: {
      fontSize: '0.8rem',
    },
    info: {
      cursor: 'help',
      fontSize: '0.5rem',
      marginLeft: styles.spacing.tiny,
    },
    infoIcon: {
      fontSize: '0.5rem',
      color: styles.color.lightGrey,
    },
    closeIcon: {
      width: '1.3rem !important',
      height: '1.3rem !important',
      cursor: 'pointer',
      color: styles.color.darkGrey,
    },
    uploadBtn: {
      color: styles.color.white,
      padding: '0.8rem 1.2rem',
      borderRadius: '1rem',
      cursor: 'pointer',
      display: 'flex',
      width: 'max-content',
      fontSize: '1.3rem',
      alignItems: 'center',
      gap: '1.5rem',
      '&:disabled': {
        opacity: '0.6',
        transition: 'opacity 300ms ease-in-out',
        cursor: 'not-allowed',
      },
    },
    dangerLink: {
      color: styles.color.red,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500,
    },
    primaryLink: {
      cursor: 'pointer',
      fontWeight: 500,
    },
    textarea: {
      width: '100%',
      padding: '2rem 1rem',
      borderRadius: '1rem',
      border: `2px solid ${styles.color.xxLightGrey}`,
      color: styles.color.black,
      background: styles.color.xxxLightPurple,
      minHeight: '10rem',
      maxHeight: '10rem',
      resize: 'none',
      overflowY: 'auto',
    },
    textareaContainer: {
      position: 'relative',
    },
    textareaChars: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      color: styles.color.xLightGrey,
    },
    colorContainer: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      position: 'relative',
      width: 'max-content',
    },
    colorInputText: {
      borderBottom: `2px solid ${styles.color.xxLightGrey}`,
      padding: '0.8rem 1rem',
      textTransform: 'uppercase',
      background: 'white',
    },
    inputColor: {
      width: '2.5rem',
      height: '2.5rem',
      padding: '0',
      margin: '0',
      position: 'absolute',
      right: '0.2rem',
      top: '0.4rem',
    },
    checkIcon: {
      color: styles.color.medGreen,
      fontSize: '0.75rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      padding: '0.5rem',
      borderRadius: '0.5rem',
      height: '100%',
      '&:hover': {
        opacity: '0.8',
        background: 'rgba(0, 0, 0, 0.05)',
      },
    },
    loader: {
      fontSize: '0.15rem !important',
    },
  },
  light: {
    title: {
      color: styles.color.black,
    },
    uploadBtn: {
      background: styles.color.purple,
    },
    primaryLink: {
      color: styles.color.purple,
    },
    colorInputText: {
      borderBottom: `2px solid ${styles.color.xxLightGrey}`,
    },
  },
  dark: {
    title: {
      color: styles.color.white,
    },
    uploadBtn: {
      background: styles.color.lightBlue,
    },
    primaryLink: {
      color: styles.color.lightBlue,
    },
    colorInputText: {
      borderBottom: `2px solid ${styles.color.xLightGrey}`,
    },
  },
});
