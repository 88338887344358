import React, { FC } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';

interface IScrollbar extends React.HTMLAttributes<HTMLElement> {
  scrollableNodeProps?: object;
  options?: object;
}

const Scrollbar: FC<IScrollbar> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  return (
    <SimpleBar css={classes.root} {...rest}>
      {children}
    </SimpleBar>
  );
};

const useStyles = makeStyles({
  base: {},
  light: {
    root: {
      '.simplebar-scrollbar:before': {
        background: styles.color.purple,
      },
    },
  },
  dark: {
    root: {
      '.simplebar-scrollbar:before': {
        background: styles.color.lightBlue,
      },
    },
  },
});

export default Scrollbar;
