import React, { Fragment, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { defineMessages, useIntl } from 'react-intl';
import { Page } from 'models/Page';
import { WebhookDataRes } from 'services/api/schema/webhooks';
import styles from 'views/styles';
import InfiniteScrollTable, {
  IColumn,
  IColumnSort,
  IDataRenderer,
  EColumnAlign,
  ESortDirection,
  IRowRender,
} from '../InfiniteScrollTable';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Tooltip } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import tableStyles from '../InfiniteScrollTable/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { CreateWebhookDialog } from './CreateWebhookDialog';
import { DeleteWebhookDialog } from './DeleteWebhookDialog';
import { TestWebhookDialog } from './TestWebhookDialog';
import FabButton from 'views/components/Button/FabButton';
import CopyTextButton from 'views/components/CopyText/CopyTextButton';
import TagStatus from 'views/components/Tag/TagStatus';
import { ITagStatusConfig } from 'views/components/Tag/TagStatus/TagStatus';
import { formatNumber } from 'utils/conversions/conversions';

interface NewWebhook {
  url: string;
  requestsPerDay: number;
}

interface IWebhooksTable {
  webhooks: Page<WebhookDataRes>;
  testingWebhooks: string[];
  associatedWebhooks: string[];
  createWebhook: (values: NewWebhook) => void;
  testWebhook: (webhookId: string) => void;
  archiveWebhook: (webhookId: string) => void;
  sort?: IColumnSort<keyof WebhookDataRes>;
  onSort?: Function;
  teamId?: number;
  onLoadMore?: () => Promise<void>;
}

interface IWebhookData extends WebhookDataRes {
  allowDelete: boolean;
}

interface WebhookHeaderRow {
  type: 'header';
  title: string;
}

interface IShow {
  [key: string]: boolean;
}

const messages = defineMessages({
  webhookSecret: {
    id: 'webhooksTable.row.webhookSecret',
    defaultMessage: 'Webhook Secret',
  },
  status: {
    id: 'webhooksTable.row.status',
    defaultMessage: 'Status',
  },
  url: {
    id: 'webhooksTable.row.url',
    defaultMessage: 'Url',
  },
  requests_per_day: {
    id: 'webhooksTable.row.requests_per_day',
    defaultMessage: 'Rate Limit',
  },
  unlimited_requests_per_day: {
    id: 'webhooksTable.row.unlimited_requests_per_day',
    defaultMessage: 'Unlimited',
  },
  requests_per_day_daily: {
    id: 'webhooksTable.row.requests_per_day_daily',
    defaultMessage: '{requestsPerDay} / day',
  },
  cannotDeleteInUseWebhook: {
    id: 'webhooksTable.row.cannotDeleteInUseWebhook',
    defaultMessage: 'Cannot delete an in use webhook',
  },
});

const WebhooksTable = ({
  webhooks,
  testingWebhooks,
  associatedWebhooks,
  createWebhook,
  testWebhook,
  archiveWebhook,
  onSort,
  onLoadMore,
}: IWebhooksTable) => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const [isSecretShowing, showSecret] = useState<IShow>({});

  const _toggleShowing = (secret: string) => {
    showSecret({ ...isSecretShowing, [secret]: !isSecretShowing[secret] });
  };

  const columns: IColumn<keyof IWebhookData>[] = [
    {
      id: 'url',
      numeric: false,
      width: 1800,
      label: formatMessage(messages.url),
      sortable: false,
      dataRenderer: ({ rowData }: IDataRenderer<IWebhookData>) => {
        return (
          <Tooltip title={rowData.url} placement='top'>
            <div>
              <span>{`${rowData.url.substring(0, 35)}...`}</span>
            </div>
          </Tooltip>
        );
      },
    },
    {
      id: 'status',
      numeric: false,
      width: 250,
      label: formatMessage(messages.status),
      sortable: false,
      dataRenderer: ({ rowData }: IDataRenderer<IWebhookData>) => {
        return <TagStatus label={rowData.status} tagConfig={getStatusConfig(rowData.status)} />;
      },
    },
    {
      id: 'requests_per_day',
      numeric: false,
      width: 250,
      label: formatMessage(messages.requests_per_day),
      sortable: false,
      dataRenderer: ({ rowData }: IDataRenderer<IWebhookData>) => {
        return (
          <span>
            {rowData.requests_per_day
              ? formatMessage(messages.requests_per_day_daily, {
                  requestsPerDay: formatNumber(rowData.requests_per_day),
                })
              : formatMessage(messages.unlimited_requests_per_day)}
          </span>
        );
      },
    },
    {
      id: 'webhookSecret',
      numeric: false,
      width: 1000,
      label: formatMessage(messages.webhookSecret),
      sortable: false,
      dataRenderer: ({ rowData }: IDataRenderer<IWebhookData>) => {
        const secret = rowData.webhookSecret;
        const shortSecret = `${secret.substring(0, 15)}...`;
        return (
          <div css={classes.iconContainer}>
            {isSecretShowing[secret] ? (
              <span>{shortSecret}</span>
            ) : (
              <span css={classes.hidden}>{'*'.repeat(shortSecret.length)}</span>
            )}
            <div>
              {isSecretShowing[secret] ? (
                <VisibilityOffIcon css={classes.btn} onClick={() => _toggleShowing(secret)} />
              ) : (
                <VisibilityIcon css={classes.btn} onClick={() => _toggleShowing(secret)} />
              )}
              <CopyTextButton css={classes.btn} text={secret} notification={true} />
            </div>
          </div>
        );
      },
    },
    {
      id: 'actions',
      numeric: true,
      label: formatMessage({ id: 'table.head.actions' }),
      sortable: false,
      align: EColumnAlign.RIGHT,
      dataRenderer: ({ rowData }: IDataRenderer<IWebhookData>) => {
        return (
          <Fragment>
            {!associatedWebhooks.includes(rowData.webhookId) && (
              <DeleteWebhookDialog
                disabled={rowData.status !== 'failed'}
                disabledReason={formatMessage(messages.cannotDeleteInUseWebhook)}
                onDeleteWebhook={() => archiveWebhook(rowData.webhookId)}
              />
            )}
            <TestWebhookDialog onTestWebhook={() => testWebhook(rowData.webhookId)} />
          </Fragment>
        );
      },
    },
  ];

  const rowRenderer = <T extends WebhookHeaderRow>({
    key,
    columns,
    index,
    onRowClick,
    rowData,
    style,
  }: IRowRender<T>) => {
    if (rowData.type === 'header') {
      return (
        <TableRow
          key={key}
          data-testid='infiniteScrollTableRow'
          component='div'
          style={style}
          hover>
          <TableCell css={classes.row} component='div' variant='head' style={tableStyles.cell}>
            {rowData.title}
          </TableCell>
        </TableRow>
      );
    }

    const _onClick = (event: any) => {
      if (onRowClick) {
        onRowClick({ event, index, rowData });
      }
    };

    return (
      <TableRow
        key={key}
        data-testid='infiniteScrollTableRow'
        component='div'
        onClick={_onClick}
        style={style}
        hover>
        {columns}
      </TableRow>
    );
  };

  return (
    <Fragment>
      <InfiniteScrollTable<IWebhookData & WebhookHeaderRow>
        hasMore={webhooks.next_page}
        onLoadMore={onLoadMore}
        loadMoreTreshold={5}
        columns={columns}
        data={webhooks.results.map((webhook) => ({
          ...webhook,
          status: testingWebhooks.includes(webhook.webhookId) ? 'testing...' : webhook.status,
        }))}
        sort={{ orderBy: webhooks.orderBy, direction: webhooks.direction as ESortDirection }}
        onSort={onSort}
        button={
          <CreateWebhookDialog createWebhook={createWebhook}>
            {(open: () => void) => <FabButton onClick={open} testId='createWebhookBtn' />}
          </CreateWebhookDialog>
        }
        rowRenderer={rowRenderer}
      />
    </Fragment>
  );
};

const getStatusConfig = (status: string) => {
  if (status in statusesConfig) {
    return statusesConfig[status];
  }
  return statusesConfig.pending;
};

const statusesConfig: ITagStatusConfig = {
  pending: { background: styles.color.medGrey, font: styles.color.medGrey, bordered: true },
  failed: { background: styles.color.darkRed, font: styles.color.darkRed, bordered: true },
  verified: { background: styles.color.purple, font: styles.color.white },
};

const useStyles = makeStyles({
  base: {
    row: {
      height: 48,
      paddingLeft: '0.8rem !important',
      fontSize: '1.5rem !important',
      borderBottomWidth: '1px !important',
      borderBottomStyle: 'solid !important' as any,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    btn: {
      fontSize: '0.5rem',
      lineHeight: '0.7rem',
      verticalAlign: 'middle',
      marginLeft: '1rem',
      marginBottom: '0.3rem',
    },
    hidden: {
      letterSpacing: '1px',
    },
  },
  light: {
    row: {
      backgroundColor: styles.color.white,
      color: `${styles.color.xLightGrey} !important`,
      borderBottomColor: `${styles.border.color.grey} !important`,
    },
    btn: {
      color: styles.color.purple,
    },
  },
  dark: {
    row: {
      backgroundColor: styles.color.xxDarkPurple,
      color: `${styles.color.xLightBlue} !important`,
      borderBottomColor: `${styles.border.color.medDarkPurple} !important`,
    },
    btn: {
      color: styles.color.lightBlue,
    },
  },
});

export default WebhooksTable;
