import React, { Fragment, useState } from 'react';
import { css, jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { defineMessages, useIntl } from 'react-intl';
import CustomTable, { Row } from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import TransactionsTableRow from './TransactionsTableRow';
import { ITransactions, Order } from '../types';
import styles from 'views/styles';

const messages = defineMessages({
  transactionId: {
    id: 'transactionsTable.row.transactionId',
    defaultMessage: 'Transaction ID',
  },
  transactionAmount: {
    id: 'transactionsTable.row.transactionAmount',
    defaultMessage: 'Amount (NCT)',
  },
  status: {
    id: 'transactionsTable.row.status',
    defaultMessage: 'Status',
  },
  type: {
    id: 'transactionsTable.row.type',
    defaultMessage: 'Type',
  },
  timestamp: {
    id: 'transactionsTable.row.timestamp',
    defaultMessage: 'Timestamp',
  },
  noTransactions: {
    id: 'transactionsTable.noTransactions',
    defaultMessage: 'No transactions to display',
  },
  fee: {
    id: 'transactionTable.row.transactionFee',
    defaultMessage: 'Fee (NCT)',
  },
  total: {
    id: 'transactionTable.row.total',
    defaultMessage: 'Total (NCT)',
  },
});

export const TransactionsTable = ({ transactions }: { transactions: ITransactions[] }) => {
  const { formatMessage } = useIntl();
  const sortEnable = false;
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITransactions>('timestamp');
  const rows: Row<ITransactions>[] = [
    {
      id: 'timestamp',
      numeric: false,
      label: formatMessage(messages.timestamp),
      sortable: false,
      width: 100,
    },
    {
      id: 'id',
      numeric: false,
      label: formatMessage(messages.transactionId),
      sortable: false,
      width: 150,
    },
    {
      id: 'status',
      numeric: false,
      label: formatMessage(messages.status),
      sortable: false,
      width: 150,
    },
    {
      id: 'type',
      numeric: false,
      label: formatMessage(messages.type),
      sortable: false,
      width: 150,
    },
    {
      id: 'amount',
      numeric: false,
      label: formatMessage(messages.transactionAmount),
      sortable: false,
      width: 150,
    },
    {
      id: 'fee',
      numeric: false,
      label: formatMessage(messages.fee),
      sortable: false,
      width: 150,
    },
    {
      id: 'fullAmount',
      numeric: false,
      label: formatMessage(messages.total),
      sortable: false,
      width: 150,
    },
  ];

  return (
    <Fragment>
      <CustomTable<ITransactions>
        rows={transactions}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            actionAlign='left'
            showActions={false}
            renderHeaderCell={(row) => (
              <TableCell
                css={tableHeadCellStyle}
                width={row.width}
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
              >
                {sortEnable ? (
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={() => {
                      if (orderBy === row.id) {
                        setOrder(order === 'asc' ? 'desc' : 'asc');
                      } else {
                        setOrderBy(row.id);
                      }
                    }}
                  >
                    {formatMessage({ id: row.label })}
                  </TableSortLabel>
                ) : (
                  formatMessage({ id: row.label })
                )}
              </TableCell>
            )}
          />
        )}
        renderTableRow={(row: ITransactions) => <TransactionsTableRow rowData={row} />}
      />
      {transactions.length === 0 && (
        <span css={ownStyle}>{messages.noTransactions.defaultMessage}</span>
      )}
    </Fragment>
  );
};

export default TransactionsTable;

const ownStyle = css`
  color: ${styles.color.xLightGrey};
  display: block;
  font-size: 1rem;
  text-align: center;
  width: 100%;
`;

const tableHeadCellStyle = css`
  padding-left: 8px !important;
`;
