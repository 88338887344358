import { defineMessages } from 'react-intl';
import api from 'services/api';
import request from 'state/utils/request';
import { PageQuery } from 'models/Page';
import { ApiKeysActionName } from './types';
import { Thunk } from 'state/types/thunk';
import { ApiKeysRes, ApiKeyRes } from 'services/api/schema/apiKey';
import { ApiKeyData, ApiKeyForm } from 'models/ApiKey';
import { ILimitedAccessMemberForm, ILimitedAccessMember } from 'models/Team';

const messages = defineMessages({
  createFailure: {
    id: 'account.apikeys.create.failure',
    defaultMessage: 'Error creating API key. Please try again',
  },
  archiveFailure: {
    id: 'account.apikeys.archive.failure',
    defaultMessage: 'Error deleting API key. Please try again.',
  },
});

export const getApiKeys =
  (
    params?: PageQuery<ApiKeyData> & { types: 'portal-external' },
    refresh?: boolean
  ): Thunk<Promise<ApiKeysRes>> =>
  (dispatch, getState) => {
    const userId = getState().user.profile!.id;

    return dispatch(
      request(
        () => api.getApiKeys(userId, params).then((res) => res.data),
        refresh
          ? { type: ApiKeysActionName.REFRESH_API_KEYS }
          : { type: ApiKeysActionName.GET_API_KEYS }
      )
    );
  };

export const createApiKey =
  (
    apiKeyData: Partial<ApiKeyForm | ILimitedAccessMemberForm>
  ): Thunk<Promise<ApiKeyRes | ILimitedAccessMember[]>> =>
  (dispatch, getState) => {
    const userId = getState().user.profile!.id;
    return dispatch(
      request(
        () => api.createApiKey(userId, apiKeyData).then((res) => res.data),
        { type: ApiKeysActionName.CREATE_API_KEY },
        { failure: messages.createFailure }
      )
    );
  };

export const archiveApiKey =
  (id: string): Thunk<Promise<ApiKeyRes>> =>
  (dispatch, getState) => {
    const userId = getState().user.profile!.id;
    return dispatch(
      request(
        () => api.archiveApiKey(userId, id).then((res) => res.data),
        { type: ApiKeysActionName.ARCHIVE_API_KEY },
        { failure: messages.archiveFailure }
      )
    );
  };

export const clearApiKeysState = (): Thunk => (dispatch) => {
  return dispatch({ type: ApiKeysActionName.CLEAR_API_KEYS_STATE });
};
