import { CommunitiesState } from '.';
import { CommunitiesAction, CommunitiesActionName } from './types';
import { createInitialPageTimeSeries, buildPageTimeData } from 'utils/timeSeries';
import { CommunityListing, Expert } from 'models/Community';

export const initialAllCommunities = createInitialPageTimeSeries<CommunityListing>({
  orderBy: 'name',
  results: [],
});

export const initialExperts = createInitialPageTimeSeries<Expert>({
  orderBy: 'rank',
});

export const initialState: CommunitiesState = {
  all: initialAllCommunities,
  single: null,
  experts: initialExperts,
};

const communities = (
  state: CommunitiesState = initialState,
  action: CommunitiesAction
): CommunitiesState => {
  switch (action.type) {
    case CommunitiesActionName.GET_ALL_COMMUNITIES: {
      if (!action.result) {
        return state;
      } else {
        const result = buildPageTimeData(action.result, action.result.results);
        return {
          ...state,
          all: {
            ...result,
            results: result.results,
          },
        };
      }
    }

    case CommunitiesActionName.GET_COMMUNITY: {
      return {
        ...state,
        single: action.result || null,
      };
    }

    case CommunitiesActionName.GET_EXPERTS: {
      if (!action.result) {
        return state;
      }
      const result = buildPageTimeData(action.result, action.result.results);
      return {
        ...state,
        experts: result,
      };
    }

    default: {
      return state;
    }
  }
};

export default communities;
