import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import styles from 'views/styles';
import { AccountPlan } from 'models/Account';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';
import { ContactDialog } from '../ContactDialog/ContactDialog';

interface ICustomPlanProps {
  selectedPlan: AccountPlan;
}

const optionsMessages = defineMessages({
  option1: {
    id: 'pricingpage.form.options.3',
    defaultMessage: 'Request enterprise trial',
  },
  option2: {
    id: 'pricingpage.form.options.2',
    defaultMessage: 'Request a quote',
  },
  option3: {
    id: 'pricingpage.form.options.5',
    defaultMessage: 'Talk to a sales engineer',
  },
});

const Wrapper = styled(Card)`
  padding: ${styles.spacing.sm} ${styles.spacing.sm} ${styles.spacing.sm} ${styles.spacing.sm};
`;

const Content = styled.div`
  text-align: center;
  margin-top: ${styles.spacing.xs};
`;

const SubmitButton = styled(Button)`
  width: 100%;
  font-size: 1.6rem !important;
`;

export const CustomPlan = ({ selectedPlan }: ICustomPlanProps) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const _onClick = () => {};

  return (
    <Wrapper>
      <h2 css={classes.header}>
        <FormattedMessage id='customPlan.header' defaultMessage='Purchase Summary' />
      </h2>
      <h3 css={classes.subHeader}>
        {selectedPlan.name}, {intl.formatMessage({ id: `interval.${selectedPlan.interval}` })}{' '}
        <FormattedMessage id='customPlan.subHeader' defaultMessage='Subscription' />
      </h3>

      <Content>
        <FormattedMessage
          id='customPlan.text'
          defaultMessage='Thank you for your interest in the {plan} plan. Contact us to get more information on pricing and details.'
          values={{
            plan: selectedPlan.name,
          }}
        />
      </Content>

      <ContactDialog
        trigger={(props: any) => (
          <SubmitButton
            className='h-mt-xs'
            color='primary'
            variant='contained'
            onClick={_onClick}
            {...props}>
            <FormattedMessage id='customPlan.contact' defaultMessage='Contact Us' />
          </SubmitButton>
        )}
        defaultValues={{
          options: [{ label: 'Custom plan inquiry', value: 'Custom plan inquiry' }],
        }}
        options={(Object.keys(optionsMessages) as Array<keyof typeof optionsMessages>).map(
          (key) => ({
            label: intl.formatMessage(optionsMessages[key]),
            value: optionsMessages[key].defaultMessage,
          })
        )}
        withOptions
        withMessage
      />
    </Wrapper>
  );
};
const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'center',
      fontSize: styles.font.size.h5,
      marginBottom: '0.4rem',
      fontWeight: 'bold',
    },
    subHeader: {
      textAlign: 'center',
      fontSize: styles.font.size.p1,
      fontWeight: 500,
    },
  },
  light: {
    subHeader: {
      color: styles.color.purple,
    },
  },
  dark: {
    subHeader: {
      color: styles.color.white,
    },
  },
});
