import React, { Fragment } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import { find } from 'lodash';
import { ContactDialog } from './ContactDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'state/root';
import { CustomPlan } from './CustomPlan/CustomPlan';
import { TeamPlan } from './TeamPlan/TeamPlan';
import { ETabName } from '../PricingPanel';

interface IPricingSidebarProps {
  tab: ETabName;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PricingSidebar = ({ tab }: IPricingSidebarProps) => {
  const { selectedPlan, isTeam } = useSelector((state: RootState) => {
    const {
      user,
      team,
      account: { context, plans },
    } = state;

    let currentPlanId = (user.account && user.account.paymentPlan?.id) || undefined;
    if (context && context.team && team.account) {
      currentPlanId = team.account.paymentPlan?.id || undefined;
    }

    return {
      currentPlan: find(plans, (p) => p.payment_plan_id === currentPlanId),
      selectedPlan: find(
        plans,
        (p) => p.name === state.plans.selected && p.interval === state.plans.interval
      ),
      monthPlan: find(plans, (p) => p.name === state.plans.selected && p.interval === 'month'),
      isTeam: !!context && !!context.team,
    };
  });

  const renderContent = () => {
    if (
      selectedPlan &&
      ['enterprise', 'enterprise premium', 'custom'].indexOf(selectedPlan.name.toLowerCase()) !== -1
    ) {
      return <CustomPlan selectedPlan={selectedPlan} />;
    }

    if (isTeam && selectedPlan && ['basic'].indexOf(selectedPlan.name.toLowerCase()) !== -1) {
      return <TeamPlan selectedPlan={selectedPlan} />;
    }

    return (
      <Fragment>
        {selectedPlan && <CustomPlan selectedPlan={selectedPlan} />}

        <ContactDialog
          defaultValues={{ options: [{ label: 'Individual', value: 'Individual' }] }}
          withMessage
        />
      </Fragment>
    );
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};
