import React, { Fragment } from 'react'; // eslint-disable-line
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import { format, parseISO as parseDate } from 'date-fns';
import { Hunt } from 'models/Ruleset';
import { showNotification } from 'state/notification/actions';
import { downloadCSVUrl } from 'state/submission/actions';
import HoverableTableRow from 'views/components/table/HoverableTableRow';
import { btnMessages } from 'views/components/Button';
import ActionBtn from 'views/components/table/ActionBtn';
import { EScanStatus } from 'models/Submission';
import { StatusColor } from 'views/components/StatusColor';
import { HistoricalInfoModalParams } from 'views/pages/HuntPage/modals/HistoricalInfoModal';
import { useGetHuntResult } from 'views/pages/HuntPage/hooks';
import { toggleHuntsType } from 'views/pages/HuntPage/types';
import { formatDistanceToNow } from 'utils/date/date';

type AnyHunt = Hunt & { active?: boolean };

interface IHistoricalHuntRow {
  rowData: AnyHunt;
  viewResults: (id: string) => void;
  viewRuleset: (id: string) => void;
  cancelHunt: (id: string) => void;
  deleteHunt: (id: string) => void;
  showHistoricalHuntInfo: (historicalParams: HistoricalInfoModalParams) => void;
  selectedHunts: string[];
  toggleHunts: toggleHuntsType;
}

const messages = {
  errorDownloading: 'Error downloading the artifact. Please try again.',
  deleteHistHunt: 'Delete this hunt and its matches',
  viewHistHuntSummary: 'View Hunt Summary',
  saveHistHunt: 'Save Matches as CSV',
  viewHuntMatches: 'View Hunt Matches',
};

const HistoricalHuntRow = ({
  rowData,
  viewResults,
  viewRuleset,
  cancelHunt,
  deleteHunt,
  showHistoricalHuntInfo,
  toggleHunts,
  selectedHunts,
}: IHistoricalHuntRow) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { getHuntResult } = useGetHuntResult();
  const isFinished = !(rowData.status === 'PENDING' || rowData.status === 'RUNNING');
  const matches = rowData.summary?.count || 0;
  const cursorStyle = { cursor: isFinished ? 'pointer' : 'cursor' };

  const _showError = (error: string) =>
    dispatch(showNotification({ status: 'failure', message: error }));
  const _viewResults = () => (isFinished ? viewResults(rowData.id) : void 0);

  return (
    <HoverableTableRow
      dataCy='huntingResultRow'
      renderCells={() => (
        <Fragment>
          <TableCell>
            <Checkbox
              disabled={!isFinished}
              color='primary'
              checked={selectedHunts.includes(rowData.id)}
              onChange={(value) => {
                toggleHunts(value.target.checked, rowData.id);
              }}
            />
          </TableCell>
          <TableCell style={cursorStyle} onClick={_viewResults}>
            <StatusColor status={rowData.status as string} progress={rowData.progress} />
          </TableCell>
          <TableCell style={cursorStyle} onClick={_viewResults}>
            <span>{rowData.ruleset_name}</span>
          </TableCell>
          <TableCell style={cursorStyle} onClick={_viewResults}>
            <span>{matches}</span>
          </TableCell>
          <TableCell style={cursorStyle} onClick={_viewResults}>
            <span>{`${formatDistanceToNow(parseDate(rowData.created))} ago`}</span>
          </TableCell>
          <TableCell style={{ width: '19rem' }} align='left'>
            <Fragment>
              <Tooltip title={messages.viewHuntMatches} placement='top'>
                <span>
                  <ActionBtn
                    name='eye'
                    disabled={!isFinished}
                    aria-label={messages.viewHuntMatches}
                    onClick={_viewResults}
                  />
                </span>
              </Tooltip>
              <Tooltip title={messages.saveHistHunt} placement='top'>
                <span>
                  <ActionBtn
                    name='save'
                    disabled={!isFinished || matches === 0}
                    aria-label={messages.saveHistHunt}
                    onClick={async () => {
                      try {
                        const result = await getHuntResult(rowData.id, 'historical');
                        if (result.results_csv_uri) {
                          await downloadCSVUrl(
                            `${format(new Date(), 'yyyyddMM-hhmm')}-Historical-all-matches`,
                            result.results_csv_uri
                          );
                        } else {
                          _showError(messages.errorDownloading);
                        }
                      } catch (error) {
                        _showError(messages.errorDownloading);
                      }
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(btnMessages.stop)} placement='top'>
                <span>
                  <ActionBtn
                    name='stop'
                    disabled={isFinished}
                    aria-label={formatMessage(btnMessages.stop)}
                    onClick={() => cancelHunt(rowData.id)}
                  />
                </span>
              </Tooltip>
              <Tooltip title={formatMessage(btnMessages.viewRuleset)} placement='top'>
                <span>
                  <ActionBtn
                    style={{ marginLeft: 8 }}
                    name='list'
                    aria-label={formatMessage(btnMessages.viewRuleset)}
                    data-cy='huntingViewRulesetBtn'
                    onClick={(e) => {
                      e.stopPropagation();
                      viewRuleset(rowData.id);
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title={messages.viewHistHuntSummary} placement='top'>
                <span>
                  <ActionBtn
                    name='info'
                    disabled={!rowData.summary}
                    aria-label={messages.viewHistHuntSummary}
                    onClick={() =>
                      rowData.summary
                        ? showHistoricalHuntInfo({
                            rulesets: Object.entries(rowData.summary.rule).map(([key, value]) => ({
                              id: `${key}`,
                              ruleName: key,
                              results: value?.count || 0,
                            })),
                            totalMatches: rowData.summary.count,
                            data: [
                              { key: 'liveHuntId', value: rowData.livescan_id },
                              { key: 'rulesetId', value: rowData.instance_id },
                            ],
                          })
                        : null
                    }
                  />
                </span>
              </Tooltip>
              <Tooltip title={messages.deleteHistHunt} placement='top'>
                <span>
                  <ActionBtn
                    style={{ marginLeft: 8 }}
                    name='delete'
                    disabled={
                      rowData.status === EScanStatus.PENDING ||
                      rowData.status === EScanStatus.RUNNING
                    }
                    aria-label={messages.deleteHistHunt}
                    data-cy='huntingDeleteBtn'
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteHunt(rowData.id);
                    }}
                  />
                </span>
              </Tooltip>
            </Fragment>
          </TableCell>
        </Fragment>
      )}
    />
  );
};

export default HistoricalHuntRow;
