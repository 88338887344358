import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet, HelmetProps } from 'react-helmet';
import { useIntl } from 'react-intl';
import config from 'config';
import { useUser } from 'views/components/providers/UserProvider';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';
import usePrivateCommunityTheme from 'hooks/usePrivateCommunityTheme';
import tenant from 'tenants';

export interface ISEO {
  title?: string;
  description?: string;
  meta?: HelmetProps['meta'];
}

const SEO = ({ title, description, meta }: ISEO) => {
  const intl = useIntl();
  const location = useLocation();
  const user = useUser();
  const ctx = contextAccount(store);
  const { variant: privateComminityTheme, theme } = usePrivateCommunityTheme();

  const site = {
    url: `${config.url}${location.pathname}`,
    name: tenant.name,
    title: tenant.title,
    description: tenant.description,
    author: tenant.author,
    image: tenant.image,
  };

  const metaTitle = title || site.title;
  const metaDescription = description || site.description;
  let metaData = meta || [];
  metaData =
    process.env.NODE_ENV !== 'production'
      ? [...metaData, { name: 'robots', content: 'noindex, nofollow' }]
      : metaData;

  let accountName;
  if (user.teams.length) {
    accountName = user?.context?.team ? user.context.team.name : user.name;
  }
  const siteName = metaTitle.startsWith(site.name) ? null : site.name;
  const titleTemplate = [
    '%s',
    !!accountName
      ? `${accountName} ${ctx?.isPrivate ? 'Private' : ''}`.split(' ').filter(Boolean).join(' ')
      : '',
    siteName,
  ]
    .filter((s) => !!s)
    .join(' | ');

  return (
    <Helmet
      htmlAttributes={{ lang: intl.locale }}
      title={metaTitle}
      titleTemplate={titleTemplate}
      link={[
        { rel: 'alternate', hrefLang: intl.locale, href: site.url },
        { rel: 'canonical', href: site.url },
        {
          rel: 'icon',
          href:
            tenant.assets?.favicon ||
            `${config.url}/${
              ctx?.isPrivate ? `${privateComminityTheme}-${theme}-favicon` : 'favicon.ico'
            }`,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:locale',
          content: intl.locale.replace('-', '_'),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:image',
          content: site.image,
        },
        {
          property: 'og:image:secure_url',
          content: site.image,
        },
        {
          property: 'og:image:width:',
          content: '1200',
        },
        {
          property: 'og:image:height',
          content: '500',
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:url',
          content: site.url,
        },
        {
          property: 'og:site_name',
          content: site.title,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:title',
          content: metaTitle,
        },
        {
          name: 'twitter:site',
          content: site.author,
        },
        {
          property: 'twitter:image',
          content: site.image,
        },
        {
          name: 'twitter:creator',
          content: site.author,
        },
        ...metaData,
      ]}
    />
  );
};

export default SEO;
