import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { ModalState } from 'state/modal';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { cancelPendingPlan } from 'state/account/actions';
import { btnMessages } from 'views/components/Button';
import ConfirmModal from 'views/components/Modal/ConfirmModal';

export interface CancelPendingPlanModalParams {
  context: 'user' | 'team';
  accountNumber: number;
}

const messages = defineMessages({
  heading: {
    id: 'cancelPendingPlan.modal.heading',
    defaultMessage: 'Cancel Plan Change',
  },
  text: {
    id: 'cancelPendingPlan.modal.text',
    defaultMessage: 'Are you sure you want to cancel your plan change?',
  },
});

const CancelPendingPlanModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch<Dispatch>();

  const { context, accountNumber } = useSelector(
    ({ modal }: { modal: ModalState<CancelPendingPlanModalParams> }) => modal.params
  );

  const _handleCancelPlanChange = async () =>
    await dispatch(cancelPendingPlan(context, accountNumber));

  const _closeModal = () => dispatch(closeModal());

  return (
    <ConfirmModal
      testId='cancelPendingPlanModal'
      isLoading={false}
      heading={intl.formatMessage(messages.heading)}
      text={intl.formatMessage(messages.text)}
      submitBtnText={intl.formatMessage(btnMessages.confirm)}
      onSubmit={_handleCancelPlanChange}
      onCancel={_closeModal}
    />
  );
};

export default CancelPendingPlanModal;
