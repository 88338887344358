/**
 * The app's configuration registry.
 * This gets passed to the config function, which returns a config object.
 */
const registry = {
  url: 'REACT_APP_URL',
  apiUrl: 'REACT_APP_API_URL',
  auth0Url: 'REACT_APP_AUTH0_URL',
  auth0ClientId: 'REACT_APP_AUTH0_CLIENT_ID',
  auth0Audience: 'REACT_APP_AUTH0_AUDIENCE',
  gaID: 'REACT_APP_GA_ID',
  deployId: 'REACT_APP_DEPLOY_ID',
  walletClaimSalt: { name: 'WALLET_CLAIM_SALT', default: 'gW$f-k' },
  etherscanUrl: {
    name: 'REACT_APP_ETHERSCAN_URL',
    default: 'https://holesky.etherscan.io/tx/{txid}',
  },
  tokenExpirationSec: {
    name: 'TOKEN_EXPIRATION_SEC',
    default: '60',
  },
  otherTokenExpirationSec: {
    // For less sensitive operations, like team management
    name: 'OTHER_TOKEN_EXPIRATION_SEC',
    default: '3600',
  },
};

/**
 * Generate the app's configuration.
 *
 * Pass it a registry object, and it will check to make
 * sure each environment variable exists or has a default value,
 * then return a config object to use in the application.
 */
export const initializeConfig = <T>(registryObject: T) =>
  Object.entries(registryObject).reduce((acc, [key, value]) => {
    const envName = typeof value === 'string' ? value : value['name'];
    const envValue = process.env[envName];
    if (envValue === undefined) {
      if (typeof value === 'object' && value['default'] !== undefined) {
        // if env var is not set then check for default value
        return Object.assign(acc, { [key]: value['default'] });
      }
      // Throw an error if an expected environment variable is undefined
      // and doesn't have a default value
      throw new Error(`Undefined environment variable: ${envName}`);
    }
    return Object.assign(acc, { [key]: envValue });
  }, {} as Record<string, string>);

/**
 * Return cookie value, or undefined if isn't set or inaccessible.
 */
export const getCookieVal = (cookieName: string): string | undefined => {
  return document.cookie
    .split('; ')
    .filter((row) => row.startsWith(`${cookieName}=`))
    .map((c) => c.split('=')[1])[0];
};

export default initializeConfig(registry);
