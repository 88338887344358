import { uniq } from 'lodash';
import { IntlShape } from 'react-intl';
import api from 'services/api';
import { PageAttributes } from 'models/Page';
import { ApiKey } from 'models/ApiKey';
import { getEnumKeys } from 'utils/types';
import { ETeamRole } from 'models/Team';

export const getCommunitiesByTeam = (userId: number, teamId: number) =>
  new Promise<string[]>(async (resolve, reject) => {
    const initialParams = { limit: 50 } as PageAttributes<ApiKey>;
    const params = Object.assign({}, initialParams, { teamIds: [teamId] });
    try {
      const apiKeys = await api.getApiKeys(userId, params).then((res) => res.data.results);
      const communities = uniq(
        apiKeys.reduce((a: string[], c) => {
          c.availableCommunities?.forEach((community) => a.push(community));
          return a;
        }, [])
      );
      resolve(communities);
    } catch (error) {
      reject(error);
    }
  });

export const teamRoleOptions = (intl: IntlShape) =>
  getEnumKeys(ETeamRole).map((value) => ({
    label: intl.formatMessage({ id: `team.role.${value}` }),
    value,
  }));
