import { TeamState } from '.';
import { TeamAction, TeamActionName } from './types';
import { createInitialPagination, buildPaginatedData } from 'utils/pagination';
import { transformUsageData } from 'state/utils/usage';
import { TeamMember, TeamMemberInvitation } from 'models/Team';
import { AuthAction, AuthActionName } from 'state/auth/types';

export const initialAllMembersPagination = createInitialPagination<TeamMember>({
  orderBy: 'email',
  limit: 75,
  results: [],
});

export const initialTeamMembersPagination = createInitialPagination<TeamMember>({
  orderBy: 'email',
  results: [],
});

export const initialTeamInvitationsPagination = createInitialPagination<TeamMemberInvitation>({
  orderBy: 'createdAt',
  results: [],
});

export const initialState: TeamState = {
  single: null,
  account: null,
  usage: [],
  allMembers: initialAllMembersPagination,
  members: initialTeamMembersPagination,
  invitations: initialTeamInvitationsPagination,
  communities: [],
};

const team = (state: TeamState = initialState, action: TeamAction | AuthAction): TeamState => {
  switch (action.type) {
    case AuthActionName.LOGOUT: {
      return initialState;
    }

    case TeamActionName.GET_TEAM: /* fallthrough */
    case TeamActionName.UPDATE_TEAM: /* fallthrough */
    case TeamActionName.CREATE_TEAM: {
      if (!action.result) {
        return state;
      }
      return { ...state, single: action.result };
    }

    case TeamActionName.ARCHIVE_TEAM: {
      return initialState;
    }

    case TeamActionName.GET_TEAM_ACCOUNT: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        account: action.result,
      };
    }

    case TeamActionName.GET_TEAM_USAGE: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        usage: transformUsageData(action.result),
      };
    }

    case TeamActionName.GET_ALL_TEAM_MEMBERS: {
      if (!action.result) {
        return state;
      }
      const result = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        allMembers: {
          ...result,
          results: result.results,
        },
      };
    }

    case TeamActionName.REFRESH_TEAM_MEMBERS: {
      if (!action.result) {
        return state;
      }
      return { ...state, members: buildPaginatedData(action.result, action.result.results) };
    }

    case TeamActionName.REFRESH_TEAM_INVITATIONS: {
      if (!action.result) {
        return state;
      }
      return { ...state, invitations: buildPaginatedData(action.result, action.result.results) };
    }

    case TeamActionName.GET_TEAM_INVITATIONS: {
      if (!action.result) {
        return state;
      }
      const result = buildPaginatedData(action.result, action.result.results);
      return {
        ...state,
        invitations: {
          ...result,
          results: [...state.invitations.results, ...result.results],
        },
      };
    }

    case TeamActionName.GET_TEAM_COMMUNITIES: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        communities: action.result,
      };
    }

    default: {
      return state;
    }
  }
};

export default team;
