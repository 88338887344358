import React from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import { PricingComparePlans, ETabName } from '.';
import styled from '@emotion/styled';

interface IPricingPanelProps {
  onChangeTab?: (tab: ETabName) => void;
}

const StyledPanel = styled(Panel)`
  overflow-x: hidden;
`;

export const PricingPanel = ({ onChangeTab }: IPricingPanelProps) => (
  <Card>
    <StyledPanel>
      <PricingComparePlans onChangeTab={onChangeTab} />
    </StyledPanel>
  </Card>
);
