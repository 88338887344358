import api from 'services/api';
import { useUser } from 'views/components/providers/UserProvider';
import { getErrorMessage, RequestError } from 'utils/error';
import { WebhookData } from 'models/Webhook';

export const useGetWebhook = () => {
  const user = useUser();

  const accountNumber = user.context?.accountNumber || user.accountNumber;

  async function getWebhook(webhookId: string): Promise<{ webhook?: WebhookData; error?: string }> {
    try {
      const response = await api.getWebhook(accountNumber, webhookId);
      return { webhook: response.data };
    } catch (error) {
      const errorMessage = getErrorMessage(error as RequestError);
      return {
        error: errorMessage,
      };
    }
  }

  return {
    getWebhook,
  };
};
