import React from 'react';
import { Switch, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import ability from 'config/ability';
import joinUrl from 'utils/joinUrl';
import ProtectedRoute from 'views/components/auth/ProtectedRoute';
import Card from 'views/components/layout/Card';
import Panel from 'views/components/layout/Panel';
import TeamLimitedAccessTab from '../../TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamLimitedAccessTab';
import { useDispatch } from 'react-redux';
import { clearApiKeysState } from 'state/apiKeys/actions';
import TeamMembersTab from './TeamDetailTabs/TeamMembersTab';
import TeamInvitationsTab from './TeamDetailTabs/TeamInvitationsTab';
import TeamApiKeysTab from './TeamDetailTabs/TeamApiKeysTab';
import TeamWebhooksTab from './TeamDetailTabs/TeamWebhooksTab';
import { TeamUsageTab } from './TeamDetailTabs/TeamUsageTab';
import { WalletsTab } from 'views/pages/AccountPage/AccountPanel/AccountTabs/WalletsTab';
import { useUser } from 'views/components/providers/UserProvider';
import { canManageWallets } from 'views/pages/AccountPage/AccountPanel/auth';
import PaginatedTabs, { TabItem } from 'views/components/PaginatedTabs/PaginatedTabs';
import { TeamAdvancedTab } from './TeamDetailTabs/TeamAdvancedTab';
import { TeamIntegrationsTab } from './TeamDetailTabs/TeamIntegrationsTab';
import { TeamTeamActivityTab } from './TeamDetailTabs/TeamTeamActivityTab';
import { useIsPageEnabled } from 'hooks/useIsPageEnabled';
import { Route } from 'tenants/components';
import useTeamMemberAccess from 'hooks/useTeamMemberAccess';
import useTeamHasFeature from 'hooks/useTeamHasFeature';
import { ETeamRole } from 'models/Team';

const TeamDetailPanel = () => {
  const user = useUser();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const page = useIsPageEnabled();
  const dispatch = useDispatch();

  const { hasFeature: hasManualAnalysisFeature } = useTeamHasFeature('manual_analysis_provider');
  const { teamMemberAccess, hasAccess, loading } = useTeamMemberAccess();
  const hasTeamMemberAccess = teamMemberAccess || hasAccess;
  const { teams, context: accountContext } = useUser();

  const isAdminOrOwner = teams
    .find((team) => team.accountNumber === accountContext?.accountNumber)
    ?.roles.some((role) => [ETeamRole.teamOwner, ETeamRole.teamAdmin].includes(role as ETeamRole));

  const urls = {
    settings: joinUrl(match.url, 'settings'),
    members: joinUrl(match.url, 'members'),
    invitations: joinUrl(match.url, 'invitations'),
    apiKeys: joinUrl(match.url, 'api-keys'),
    webhooks: joinUrl(match.url, 'webhooks'),
    usage: joinUrl(match.url, 'usage'),
    notifications: joinUrl(match.url, 'notifications'),
    wallets: joinUrl(match.url, 'wallets'),
    advanced: joinUrl(match.url, 'advanced'),
    integrations: joinUrl(match.url, 'integrations'),
    teamActivity: joinUrl(match.url, 'activity'),
    limitedAccess: joinUrl(match.url, 'limited-access'),
  };

  const tabs = [
    {
      value: urls.members,
      label: 'Members',
      dataTestId: 'teamMembers',
      hidden: !page.isEnabled('team-members') || !hasTeamMemberAccess,
    },
    {
      value: urls.limitedAccess,
      label: 'Limited Access',
      dataTestId: 'teamLimitedAccess',
      hidden:
        !page.isEnabled('team-limited-access') || !isAdminOrOwner || !hasManualAnalysisFeature,
    },
    {
      value: urls.invitations,
      label: 'Invitations',
      dataTestId: 'teamInvitations',
      hidden: ability.cannot('manage', 'Team') || !page.isEnabled('team-invitations'),
    },
    {
      value: urls.apiKeys,
      label: 'API Keys',
      dataTestId: 'teamApiKeys',
      hidden: !page.isEnabled('team-api-keys'),
    },
    {
      value: urls.integrations,
      label: 'Integrations',
      hidden: ability.cannot('manage', 'Integrations') || !page.isEnabled('team-integrations'),
    },
    {
      value: urls.webhooks,
      label: 'Webhooks',
      dataTestId: 'teamWebhooks',
      hidden: ability.cannot('manage', 'Webhooks') || !page.isEnabled('team-webhooks'),
    },
    {
      value: urls.wallets,
      label: 'Wallets',
      hidden: !canManageWallets(user) || !page.isEnabled('team-wallets'),
    },
    {
      value: urls.usage,
      label: 'Usage',
      dataTestId: 'teamUsage',
      hidden: !page.isEnabled('team-usage'),
    },
    {
      value: urls.teamActivity,
      label: 'Activity',
      dataTestId: 'teamActivity',
      hidden: !page.isEnabled('team-activity'),
    },
    {
      value: urls.advanced,
      label: 'Advanced',
      hidden: ability.cannot('manage', 'Team') || !page.isEnabled('team-advanced'),
    },
  ].filter(Boolean) as TabItem[];

  const _handleChange = (_: React.ChangeEvent<any>, pathname: string) => {
    if (pathname.includes('limited-access') || pathname.includes('api-keys')) {
      dispatch(clearApiKeysState());
    }

    history.push(`${pathname}`);
  };

  if (loading) return null;

  return (
    <Card>
      <PaginatedTabs
        tabs={tabs}
        maxTabs={4}
        indicatorColor='primary'
        value={location.pathname}
        // @ts-expect-error
        onChange={_handleChange}
      />
      <Panel>
        <Switch>
          <Route
            exact
            path={urls.members}
            component={TeamMembersTab}
            show={page.isEnabled('team-members') && hasTeamMemberAccess}
            fallbackUrl={urls.apiKeys}
          />
          <Route
            exact
            path={urls.limitedAccess}
            component={TeamLimitedAccessTab}
            show={hasManualAnalysisFeature && isAdminOrOwner}
          />
          <ProtectedRoute
            exact
            path={urls.invitations}
            canI='manage'
            a='Team'
            component={TeamInvitationsTab}
          />
          <Route
            exact
            path={urls.apiKeys}
            component={TeamApiKeysTab}
            show={page.isEnabled('team-api-keys')}
          />
          <ProtectedRoute
            exact
            canI='manage'
            a='Integrations'
            path={urls.integrations}
            component={TeamIntegrationsTab}
          />
          <Route
            exact
            path={urls.webhooks}
            component={TeamWebhooksTab}
            show={page.isEnabled('team-webhooks')}
          />
          <Route
            exact
            path={urls.wallets}
            component={WalletsTab}
            show={canManageWallets(user) && page.isEnabled('team-wallets')}
          />
          <Route
            exact
            path={urls.usage}
            component={TeamUsageTab}
            show={page.isEnabled('team-usage')}
          />
          <Route
            exact
            path={urls.teamActivity}
            component={TeamTeamActivityTab}
            show={page.isEnabled('team-activity')}
          />
          <ProtectedRoute
            exact
            canI='manage'
            a='Team'
            path={urls.advanced}
            component={TeamAdvancedTab}
            show={page.isEnabled('team-advanced')}
          />
          <Redirect exact from={urls.settings} to={tabs.filter((tab) => !tab.hidden)[0].value} />
          <Redirect exact from={match.url} to={tabs.filter((tab) => !tab.hidden)[0].value} />
          <Redirect to='/404' />
        </Switch>
      </Panel>
    </Card>
  );
};
export default TeamDetailPanel;
