import React, { useState } from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import api from 'services/api';
import { closeModal } from 'state/modal/actions';
import { SignupValues } from 'models/Account';
import { ModalState } from 'state/modal';
import { ErrorKeys, translateError, RequestError } from 'utils/error';
import styles from 'views/styles';
import Modal from 'views/components/Modal';
import ComparePlansForm, {
  IComparePlansFormValues,
} from 'views/components/account/ComparePlans/ComparePlansForm';

export interface ComparePlansModalParams {
  values?: Partial<SignupValues>;
}

const messages = defineMessages({
  heading: {
    id: 'plansform.signup.heading',
    defaultMessage: 'Sign up for a PolySwarm subscription',
  },
});

const errorKeys: ErrorKeys = {};

const ComparePlansModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<RequestError | undefined>();

  const intl = useIntl();
  const dispatch = useDispatch();

  const { values } = useSelector(
    ({ modal }: { modal: ModalState<ComparePlansModalParams> }) => modal.params
  );

  const _closeModal = () => dispatch(closeModal());

  const _handleSubmit = async (values: IComparePlansFormValues) => {
    const { confirmEmail, ...rest } = values;
    setIsLoading(true);
    try {
      await api.submitPricingForm(rest);
      setIsLoading(false);
      _closeModal();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Modal>
      <ComparePlansForm
        css={ownStyle}
        initialValues={values}
        heading={intl.formatMessage(messages.heading)}
        isLoading={isLoading}
        errorMessage={translateError(intl, errorKeys, error)}
        onSubmit={_handleSubmit}
        onCancel={_closeModal}
      />
    </Modal>
  );
};

const ownStyle = css`
  padding: ${styles.spacing.md};
  width: 600px;
`;

export default ComparePlansModal;
